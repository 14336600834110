// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// // import { FaCheckCircle } from "react-icons/fa";

// const Solutions = () => {
//     const [selectedSection, setSelectedSection] = useState("CarDeal Quotation System");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">Solutions</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">Innovative strategies tailored to solve complex challenges with excellence.</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "CarDeal Quotation System" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("CarDeal Quotation System")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             CarDeal Quotation System
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Transport Bilty Software" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Transport Bilty Software")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Transport Bilty Software
//                         </li>
//                         {/* <li
//                             className={`list-group-item ${selectedSection === "eLearning Management System" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("eLearning Management System")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             eLearning Management System
//                         </li> */}
//                         <li
//                             className={`list-group-item ${selectedSection === "Property Management System" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Property Management System")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Property Management System
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "CarDeal Quotation System" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">CarDeal Quotation System</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">
//                                 A robust solution tailored for vehicle showrooms, designed to simplify the management of customer quotations. It enables importing and exporting quotation data effortlessly, ensuring data accuracy and consistency across processes. The system includes WhatsApp notifications for instant customer communication, enhancing engagement and response time. Additionally, it tracks customer interactions, providing valuable insights for better relationship management and improved sales performance.
//                             </p>
//                         </div>
//                     )}

//                     {selectedSection === "Transport Bilty Software" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Transport Bilty Software</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">
//                                 A comprehensive tool built to support vehicle showrooms in generating and managing custom quotations with precision. It optimizes the pricing process, reducing manual errors and saving time for sales teams. The software offers advanced features to handle complex quotation needs, ensuring seamless workflow integration. Its user-friendly interface empowers businesses to boost efficiency, maintain consistency, and deliver an exceptional customer experience in vehicle sales.</p>
//                         </div>
//                     )}

//                     {/* {selectedSection === "eLearning Management System" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">eLearning Management System</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2"></p>
//                         </div>
//                     )} */}

//                     {selectedSection === "Property Management System" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Property Management System</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">
//                             A Property Management System designed to simplify property listings, transactions, and customer interactions. It streamlines the buying and selling process, with features for managing property details, pricing, and quotations. WhatsApp notifications enhance customer communication, while tracking interactions provides valuable insights for improved sales and relationship management.
//                                 </p>
//                         </div>
//                     )}

//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Solutions;



import React, { useState } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle } from "react-icons/fa";

const Solutions = () => {
    const [selectedSection, setSelectedSection] = useState("CarDeal Quotation System");

    return (
        <Container className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar (col-md-4) */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">Solutions</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">
                                Innovative strategies tailored to solve complex challenges with excellence.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    className={selectedSection === "CarDeal Quotation System" ? "active" : ""}
                                    onMouseEnter={() => setSelectedSection("CarDeal Quotation System")}
                                    style={{ cursor: "pointer" }}
                                >
                                    CarDeal Quotation System
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className={selectedSection === "Transport Bilty Software" ? "active" : ""}
                                    onMouseEnter={() => setSelectedSection("Transport Bilty Software")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Transport Bilty Software
                                </ListGroup.Item>
                                {/* <ListGroup.Item
                                    className={selectedSection === "eLearning Management System" ? "active" : ""}
                                    onMouseEnter={() => setSelectedSection("eLearning Management System")}
                                    style={{ cursor: "pointer" }}
                                >
                                eLearning Management System
                                </ListGroup.Item> */}
                                <ListGroup.Item
                                    className={selectedSection === "Property Management System" ? "active" : ""}
                                    onMouseEnter={() => setSelectedSection("Property Management System")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Property Management System
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content (col-md-8) */}
                <Col md={8} className="bg-white">
                    {selectedSection === "CarDeal Quotation System" && (
                        <div className="w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">CarDeal Quotation System</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">
                                A robust solution tailored for vehicle showrooms, designed to simplify the management of customer quotations. It enables importing and exporting quotation data effortlessly, ensuring data accuracy and consistency across processes. The system includes WhatsApp notifications for instant customer communication, enhancing engagement and response time. Additionally, it tracks customer interactions, providing valuable insights for better relationship management and improved sales performance.
                            </p>
                        </div>
                    )}

                    {selectedSection === "Transport Bilty Software" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Transport Bilty Software</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">
                                A comprehensive tool built to support vehicle showrooms in generating and managing custom quotations with precision. It optimizes the pricing process, reducing manual errors and saving time for sales teams. The software offers advanced features to handle complex quotation needs, ensuring seamless workflow integration. Its user-friendly interface empowers businesses to boost efficiency, maintain consistency, and deliver an exceptional customer experience in vehicle sales.
                            </p>
                        </div>
                    )}

                    {/* {selectedSection === "eLearning Management System" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">eLearning Management System</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2"></p>
                        </div>
                    )} */}

                    {selectedSection === "Property Management System" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Property Management System</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">
                                A Property Management System designed to simplify property listings, transactions, and customer interactions. It streamlines the buying and selling process, with features for managing property details, pricing, and quotations. WhatsApp notifications enhance customer communication, while tracking interactions provides valuable insights for improved sales and relationship management.
                            </p>
                        </div>
                    )}

                    {/* Footer for col-md-8 */}
                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Solutions;
