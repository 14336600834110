// import React from 'react';
// import {  Card,Container, Row, Col,Tab, Tabs } from 'react-bootstrap';
// import { FaCheckCircle } from 'react-icons/fa';
// import cloudhosting from '../Images/Only-Cloud-Web-Hosting-BigHost-India.png';
// import '../MainCSS/Services.css'; // Import external CSS file
// import ReviewsSection from "../MainPages/Reviews";
// import { IoIosArrowForward } from "react-icons/io";
// // import FeaturesCards from '../MainPages/FeaturesCards'
// import '../MainCSS/card.css';
// import '../MainCSS/FeaturesCards.css';
// import img1 from '../Images/Cloud-Technology-Provider-BigHost-India.png';
// import img2 from '../Images/Only-Cloud-Web-Hosting-BigHost-India.png';

// const CloudHostingSection = () => {

//   // Pricing card data
// const pricingData = [
//   {
//     id: 1,
//     title: "Cloud - HSH1",
//     price: "₹119",
//     features: [
//       "1 Websites",
//       "1 GB NVMe Disk Space",
//       "2GB LVE RAM per cPanel",
//       "CPU Cores 2 vCPU",
//       "IO 50 MB/S Port",
//       "1 cPanel Control Panel",
//       "5 Email Account",
//       "1GB Quota per Email",
//     ]
//   },
//   {
//     id: 2,
//     title: "Cloud - HSH2",
//     price: "₹239",
//     features: [
//       "2 Websites",
//       "5 GB NVMe Disk Space",
//       "2 GB LVE RAM per cPanel",
//       "CPU Cores 2 vCPU",
//       "IO 50 MB/S Port",
//       "1 cPanel Control Panel",
//       "20 Email Account",
//       "1 GB Quota per Email"
//     ]
//   },
//   {
//     id: 3,
//     title: "Cloud - HSH3",
//     price: "₹479",
//     features: [
//       "3 Websites",
//       "10 GB NVMe Disk Space",
//       "2 GB LVE RAM per cPanel",
//       "CPU Cores 2 vCPU",
//       "IO 50 MB/S Port",
//       "1 cPanel Control Panel",
//       "50 Email Account",
//       "1 GB Quota per Email"
//     ]
//   },
//   {
//     id: 4,
//     title: "Cloud - HSH4",
//     price: "₹1124",
//     features: [
//       "10 Websites",
//       "50 GB NVMe Disk Space",
//       "2 GB LVE RAM per cPanel",
//       "CPU Cores 2 vCPU",
//       "IO 50 MB/S Port",
//       "1 cPanel Control Panel",
//       "100 Email Account",
//       "2 GB Quota per Email"
//     ]
//   }
// ];

// const projects = [
//   {
//     title: 'Cloud Technology',
//     image: img1,
//     description: 'Our services are powered by Cloud Technology that provides outstanding Reliability and Redundancy. Where the standard bare-metal based Web Hosting fails, is where the Cloud technology shines – the availability to upgrade the hardware components with zero downtime and zero data loss.',
//   },
//   {
//     title: 'Lightning fast Loading Speed',
//     image: img2,
//     description: 'The loading speed of a website is a vital SEO ranking factor today. Our web hosting plans are optimized to meet loading speed requirements out-of-the-box. For modern, complex websites, we offer exclusive on-demand optimization. This ensures every hosted website achieves its best performance.',
//   },
//   {
//     title: 'Web Security Data Protection',
//     image: img1,
//     description: 'Multiple layers of security firewalls ensure the safety of our entire web hosting environment. Thanks to the innovations in the AI field, our security services will adapt to every type of malicious attack! From Opensource applications to custom scripts, we are confident that your websites will remain safe with us!',
//   },
//   {
//     title: 'Guaranteed stability and uptime',
//     image: img2,
//     description: 'All our services come with a 99.9% uptime guarantee as standard. Our technical experts are committed to delivering optimal reliability and monitoring our infrastructure 24/7/365 to ensure that your website is always available for your visitors.',
//   },
//   {
//     title: 'Technical Support from our experts',
//     image: img1,
//     description: 'BigHost India is a friendly team of hosting experts that’s always ready and available to assist and ensure your website succeeds. Chat with us using the live chat link below, or give us a call. You’ll be speaking directly to a knowledgeable engineer.',
//   },
//   {
//     title: 'Free website migration',
//     image: img2,
//     description: 'Our experts ensure seamless technical migrations with top-notch data security. We handle every aspect of the process, giving you peace of mind. Transitioning to our platform is smooth, reliable, and stress-free. Best of all, we offer this service at no extra cost.',
//   },
// ];

//   return (
//     <Container fluid className='m-0 p-0 cloud-hosting-maincontainer'>
//       <div className="cloud-hosting-section">
//         <Container fluid className="cloud-hosting-container">
//           <Row className="align-items-center justify-content-between text-start">
//             <Col md={8}>
//               <h1 className="cloud-hosting-title ">
//                 The Only Cloud Hosting
//                 <span className="title-highlight "> You'll Ever Need</span>
//               </h1>
//               <p className="cloud-hosting-description">
//                 Businesses and individuals around the world turn to BigHost for our superior cloud web hosting solutions.
//               </p>
//               <p className="cloud-hosting-price ">
//                 Starting at Rs.119/mo including:
//               </p>
//               <ul className="cloud-hosting-list list-unstyled ">
//                 <Row>
//                   <Col lg={6}>
//                     <li><FaCheckCircle className="check-icon" /> FREE SSL</li>
//                     <li><FaCheckCircle className="check-icon" /> FREE Website Migration</li>
//                     <li><FaCheckCircle className="check-icon" /> CageFS Hack Protection</li>
//                   </Col>
//                   <Col lg={6}>
//                     <li><FaCheckCircle className="check-icon" /> FREE Daily Backups</li>
//                     <li><FaCheckCircle className="check-icon" /> WordPress LiteSpeed Cache</li>
//                     <li><FaCheckCircle className="check-icon" /> Imunify360 Security</li>
//                   </Col>
//                 </Row>
//               </ul>
//             </Col>
//             <Col md={4}>
//               <div className="image-container">
//                 <img
//                   src={cloudhosting}
//                   alt="Cloud Hosting Illustration"
//                   className="cloud-hosting-image"
//                 />
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>

//       <Row className='text-center cloud-section mb-4 m-0  pt-5 '>

//         <h2 className="cloud-hosting-subtitle">Affordable and Powerful <span className="subtitle-highlight ">Cloud cPanel</span><br/> Hosting</h2>
//         <p>Whether you’re a startup, established business, blogger, eCommerce, or manage multiple websites, we offer the fastest cloud hosting plans to grow with your site. Pick a plan below, and start building towards success.</p>

//         <Row className="justify-content-center pricing-card-container p-0 m-0">
//           {pricingData.map((card) => (
//             <Col
//               key={card.id}
//               xs={12}
//               sm={6}
//               md={4}
//               lg={3}
//               className="d-flex justify-content-center m-0 py-4 p-0 "
//             >
//               <div className="pricing-card mx-2">
//                 {/* Background Circles */}
//                 <div className="circle-1"></div>
//                 <div className="circle-2"></div>
//                 <div className="circle-3"></div>
//                 <div className="circle-4"></div>
//                 <div className="circle-5"></div>

//                 {/* Top Section with Waves */}
//                 <div className="card-top">
//                   <svg
//                     className="top-wave top-wave-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 1440 800" /* Increased height */
//                   >
//                     <path
//                       fill="#FE8200"
//                       fillOpacity="0.8"
//                       d="M0,300L48,290.7C96,281,192,260,288,245.3C384,230,480,220,576,215.3C672,210,768,230,864,260.7C960,290,1056,340,1152,345.3C1248,351,1344,315,1392,300.7L1440,285L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//                     ></path>
//                   </svg>

//                   <svg
//                     className="top-wave top-wave-2"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 1440 800" 
//                   >
//                     <path
//                       fill="#FF9C40"
//                       fillOpacity="0.7"
//                       d="M0,420L48,400.7C96,380,192,340,288,315.3C384,290,480,275,576,270.3C672,265,768,285,864,315.3C960,345,1056,395,1152,400C1248,405,1344,365,1392,350.7L1440,335L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//                     ></path>
//                   </svg>

//                   <svg
//                     className="top-wave top-wave-4"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 1440 400" 
//                   >
//                     <path
//                       fill="#FFBB77"
//                       fillOpacity="0.3"
//                       d="M0,256L48,234.7C96,213,192,171,288,160C384,149,480,171,576,192C672,213,768,235,864,245.3C960,256,1056,235,1152,213.3C1248,192,1344,171,1392,160L1440,149.3L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//                     ></path>
//                   </svg>

//                   <h4 className="card-title">{card.title}</h4>
//                   <div className="price-circle">
//                     <p className='cloud-price'>
//                       {card.price}
//                       <sub>/mo</sub>
//                     </p>
//                   </div>
//                 </div>

//                 {/* Content Section */}
//                 <div className="card-content justify-content-center">
//                   <ul className="features text-start mx-5">
//                     {card.features.map((feature, index) => (
//                       <li key={index}>
//                         <FaCheckCircle className="checkcircle-card" />
//                         {feature}
//                       </li>
//                     ))}
//                   </ul>
//                   <button className="start-button mb-4">
//                     ORDER NOW <IoIosArrowForward style={{ fontSize: "15px" }} />
//                   </button>
//                 </div>

//                 {/* Bottom Section */}
//                 <div className="card-bottom">
//                   <svg
//                     className="bottom-wave"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 1440 400"
//                   >
//                     <path
//                       fill="#FE8200"
//                       fillOpacity="0.1"
//                       d="M0,64L48,96C96,128,192,192,288,224C384,256,480,256,576,234.7C672,213,768,171,864,149.3C960,128,1056,128,1152,138.7C1248,149,1344,171,1392,181.3L1440,192L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
//                     ></path>
//                   </svg>
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </Row>

//     <Row className='text-center justify-content-center cloud-section m-0 pt-4' style={{backgroundColor:'#FFFBF6'}}>
//     <Container className="tabcontainer justify-content-center" style={{ padding: "50px 30px" }}>
//             {/* Header */}
//             <Row>
//         <Col>
//           <h1 className="cloud-hosting-subtitle">Our <span className="subtitle-highlight ">Hosting</span> Features</h1>
//           <p className="text-center">
//             Discover powerful hosting features designed for businesses and developers.
//           </p>
//         </Col>
//       </Row>
//       <Tabs defaultActiveKey="accountFeatures" id="features-tabs" className=" features-tab-header" justify>
//         {/* Tab for Account Features */}
//         <Tab eventKey="accountFeatures" title="Account Features" className='features-tab'>
//         <ul className="cloud-hosting-list justify-content-center list-unstyled text-start">
//           <Row className='justify-content-center' >

//             <Col sm={6} md={3}>        
//                 <li><FaCheckCircle className="check-icon"/> cPanel Control Panel</li>
//                 <li><FaCheckCircle className="check-icon"/> Cloudlinux</li>
//                 <li><FaCheckCircle className="check-icon"/> 30 Entry Processes</li>               
//             </Col>
//             <Col sm={6} md={3}>
//             <li><FaCheckCircle className="check-icon"/> Jetbackup</li>  
//                 <li><FaCheckCircle className="check-icon"/> Public Cloud</li>
//                 <li><FaCheckCircle className="check-icon"/> Unlimited Inodes</li>            
//             </Col>
//             <Col sm={6} md={3}> 
//             <li><FaCheckCircle className="check-icon"/> WP CLI</li>
//             <li><FaCheckCircle className="check-icon"/> Softaculous</li>            
//                 <li><FaCheckCircle className="check-icon"/> I/O 50 MBPS</li>           
//             </Col>
//             <Col sm={6} md={2}>           
//             <li><FaCheckCircle className="check-icon"/> Imunify360</li>
//                 <li><FaCheckCircle className="check-icon"/> SitePad Sitebuilder</li>
//                 <li><FaCheckCircle className="check-icon"/> CageFS Security</li>           
//             </Col>
//           </Row>
//           </ul>
//         </Tab>

//         {/* Tab for Developer Tools */}
//         <Tab eventKey="developerTools" title="Developer Tools" className='features-tab' >
//         <ul className="cloud-hosting-list justify-content-center list-unstyled text-start">
//           <Row className='justify-content-center' >

//           <Col xs={6} lg={3}>
//                     <li><FaCheckCircle className="check-icon" />PHP 4.4 to 8.3</li>
//                     <li><FaCheckCircle className="check-icon" />Cron Jobs</li>
//                     <li><FaCheckCircle className="check-icon" />Mod_security</li>
//                     <li><FaCheckCircle className="check-icon" />Python App</li>
//                   </Col>
//                   <Col xs={6} lg={3}>
//                     <li><FaCheckCircle className="check-icon" />Node.js Support</li>
//                     <li><FaCheckCircle className="check-icon" />File Manager</li>
//                     <li><FaCheckCircle className="check-icon" />IP Address Blocking</li>
//                     <li><FaCheckCircle className="check-icon" />Node.js Setup</li>
//                   </Col>
//                   <Col xs={6} lg={3}>
//                     <li><FaCheckCircle className="check-icon" />SSH Access</li>
//                     <li><FaCheckCircle className="check-icon" />.htaccess</li>
//                     <li><FaCheckCircle className="check-icon" />Git Support</li>
//                     <li><FaCheckCircle className="check-icon" />Redis</li>
//                   </Col>
//                   <Col xs={6} lg={2}>
//                   <li><FaCheckCircle className="check-icon" />FTP & SFTP</li>
//                   <li><FaCheckCircle className="check-icon" />Mod_rewrite</li>
//                   <li><FaCheckCircle className="check-icon" />Memcached</li>
//                   <li><FaCheckCircle className="check-icon" />CodeIgniter</li>
//                   </Col>
//           </Row>
//           </ul>
//         </Tab>
//       </Tabs>
//     </Container>
//     </Row>
//     <Row className='text-center justify-content-center cloud-section m-0 pt-3 p-0' style={{backgroundColor:'#000'}}>
//     <Container fluid className="custom-card-container">
//       <Row>
//         <Col>
//           <h1 className="feature-subtitle">
//           Comprehensive<span className="subtitle-highlight"> Hosting </span>  Solutions
//           </h1>
//           <p className="text-center subtitle">
//           Reliable, Secure, and Optimized for Maximum Performance.
//           </p>
//         </Col>
//       </Row>
//       <Row className="justify-content-center">
//         {projects.map((project, index) => (
//           <Col lg={4} md={6} sm={12} key={index} className="d-flex justify-content-center mb-4">
//             <Card className="custom-bootstrap-card">
//               <div className="card-image-container">
//                 <Card.Img variant="top" src={project.image} className="custom-card-image" />
//                 <div className="custom-card-overlay">
//                   <Card.Text className="custom-card-description">
//                     {project.description}
//                   </Card.Text>
//                 </div>
//               </div>
//               <Card.Body className="custom-card-body">
//                 <Card.Title className="custom-card-title m-0">{project.title}</Card.Title>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//     </Row>
//     <Row className='text-center justify-content-center cloud-section m-0 pt-3 '>
//     <ReviewsSection/>
//     </Row>
//     </Container>
//   );
// };

// export default CloudHostingSection;





import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import cloudhosting from '../Images/Only-Cloud-Web-Hosting-BigHost-India.png';
import CloudTechnology from '../Images/1.jpg'
import '../MainCSS/Services.css'; // Import external CSS file
import ReviewsSection from "../MainPages/Reviews";
import { IoIosArrowForward } from "react-icons/io";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LightningFastSpeed from "../Images/2.jpg";
import SecureDataProtection from "../Images/3.jpg";
import ReliableUptime from "../Images/4.jpg";
import ExpertTechnicalSupport from "../Images/5.jpg";
import Freewebsitemigration from "../Images/6.png"
import '../MainCSS/card.css';
import '../MainCSS/Cloud-FeaturesCards.css';
import img1 from '../Images/Cloud-Technology-Provider-BigHost-India.png';
import img2 from '../Images/Only-Cloud-Web-Hosting-BigHost-India.png';
import { useNavigate } from "react-router-dom";

const CloudHostingSection = () => {
  
  const navigate = useNavigate();

  const [carouselSettings, setCarouselSettings] = useState({
    infinite: false,
    autoPlay: false,
    arrows: false,
    showDots: false,
  });

  // Detect screen size and adjust carousel settings
  useEffect(() => {
    const updateCarouselSettings = () => {
      if (window.innerWidth < 768) {
        setCarouselSettings({
          infinite: true,
          autoPlay: true,
          arrows: false,
          showDots: true,
        });
      } else {
        setCarouselSettings({
          infinite: false,
          autoPlay: false,
          arrows: false,
          showDots: false,
        });
      }
    };

    updateCarouselSettings();
    window.addEventListener("resize", updateCarouselSettings);
    return () => window.removeEventListener("resize", updateCarouselSettings);
  }, []);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1200 }, items: 4 },
    desktop: { breakpoint: { max: 1200, min: 992 }, items: 3 },
    tablet: { breakpoint: { max: 992, min: 768 }, items: 2 },
    mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  };

  // Pricing card data
  const pricingData = [
    {
      id: 1,
      title: "Cloud - HSH1",
      price: "₹149",
      features: [
        "1 Websites",
        "1 GB NVMe Disk Space",
        "2GB LVE RAM per cPanel",
        "CPU Cores 2 vCPU",
        "IO 50 MB/S Port",
        "1 cPanel Control Panel",
        "5 Email Account",
        "1GB Quota per Email",
      ],
      link: "https://billing.bighostindia.in/store/cloud-hosting/hsh1"
    },
    {
      id: 2,
      title: "Cloud - HSH2",
      price: "₹299",
      features: [
        "2 Websites",
        "5 GB NVMe Disk Space",
        "2 GB LVE RAM per cPanel",
        "CPU Cores 2 vCPU",
        "IO 50 MB/S Port",
        "1 cPanel Control Panel",
        "20 Email Account",
        "1 GB Quota per Email"
      ],
      link: "https://billing.bighostindia.in/store/cloud-hosting/hsh2"
    },
    {
      id: 3,
      title: "Cloud - HSH3",
      price: "₹599",
      features: [
        "3 Websites",
        "10 GB NVMe Disk Space",
        "2 GB LVE RAM per cPanel",
        "CPU Cores 2 vCPU",
        "IO 50 MB/S Port",
        "1 cPanel Control Panel",
        "50 Email Account",
        "1 GB Quota per Email"
      ], link: "https://billing.bighostindia.in/store/cloud-hosting/hsh3"
    },
    {
      id: 4,
      title: "Cloud - HSH4",
      price: "₹1499",
      features: [
        "10 Websites",
        "50 GB NVMe Disk Space",
        "2 GB LVE RAM per cPanel",
        "CPU Cores 2 vCPU",
        "IO 50 MB/S Port",
        "1 cPanel Control Panel",
        "100 Email Account",
        "2 GB Quota per Email"
      ], 
      link: "https://billing.bighostindia.in/store/cloud-hosting/hsh4"
    }
  ];
  const handleNavigate = (link) => {
    // Option 2: Use window.location.href (uncomment to test)
    window.location.href = link;
  };

  const projects = [
    {
      title: 'Cloud Technology',
      image: CloudTechnology,
      description: 'Our services are powered by Cloud Technology that provides outstanding Reliability and Redundancy. Where the standard bare-metal based Web Hosting fails, is where the Cloud technology shines – the availability to upgrade the hardware components with zero downtime and zero data loss.',
    },
    {
      title: 'Lightning-Fast Speed',
      image: LightningFastSpeed,
      description: 'Website loading speed is a crucial SEO ranking factor today. Our web hosting plans are designed to ensure fast loading out-of-the-box. For complex websites, we offer exclusive on-demand optimization, helping every hosted site achieve peak performance and efficiency.',
    },
    {
      title: 'Secure Data Protection',
      image: SecureDataProtection,
      description: 'Multiple layers of security firewalls ensure the safety of our entire web hosting environment. Thanks to the innovations in the AI field, our security services will adapt to every type of malicious attack! From Opensource applications to custom scripts, we are confident that your websites will remain safe with us!',
    },
    {
      title: 'Reliable Uptime',
      image: ReliableUptime,
      description: 'All our services come with a 99.9% uptime guarantee as standard. Our technical experts are committed to delivering optimal reliability and monitoring our infrastructure 24/7/365 to ensure that your website is always available for your visitors.',
    },
    {
      title: 'Expert Technical Support',
      image: ExpertTechnicalSupport,
      description: 'BigHost India is a friendly team of hosting experts that’s always ready and available to assist and ensure your website succeeds. Chat with us using the live chat link below, or give us a call. You’ll be speaking directly to a knowledgeable engineer.',
    },
    {
      title: 'Free website migration',
      image: Freewebsitemigration,
      description: 'Our experts ensure seamless technical migrations with top-notch data security. We handle every aspect of the process, giving you peace of mind. Transitioning to our platform is smooth, reliable, and stress-free. Best of all, we offer this service at no extra cost.',
    },
  ];

  return (
    <Container fluid className='m-0 p-0 cloud-hosting-maincontainer'>
      <div className="cloud-hosting-section">
        <Container fluid className="cloud-hosting-container">
          <Row className="align-items-center justify-content-between text-start">
            <Col md={8}>
              <h1 className="cloud-hosting-title ">
                Cloud Web Hosting
              </h1>
              <p className="cloud-hosting-description">
                Businesses and individuals worldwide rely on BigHost for cutting-edge cloud web hosting solutions, combining unmatched reliability, top-tier performance, and expert support to meet their unique needs.
              </p>
            </Col>
            <Col md={4}>
              <div className="image-container">
                <img
                  src={cloudhosting}
                  alt="Cloud Hosting Illustration"
                  className="cloud-hosting-image"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row className='text-center cloud-section mb-5 m-0  pt-5 '>

        <h2 className="cloud-hosting-subtitle"> Cloud cPanel Hosting </h2>
        <p style={{padding:'0 15%'}}>Whether you’re a startup, established business, blogger, eCommerce, or manage multiple websites, we offer the fastest cloud hosting plans to grow with your site. Pick a plan below, and start building towards success.</p>

        <Row className="justify-content-center pricing-card-container p-0 m-0">

          <Carousel
            responsive={responsive}
            infinite={carouselSettings.infinite}
            autoPlay={carouselSettings.autoPlay}
            arrows={carouselSettings.arrows}
            showDots={carouselSettings.showDots}
            autoPlaySpeed={3000}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
          >
            {pricingData.map((card) => (
              <Col
                key={card.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center py-4 p-0 "
              >
                <div className="pricing-card mx-2">
                  {/* Background Circles */}
                  <div className="circle-1"></div>
                  <div className="circle-2"></div>
                  <div className="circle-3"></div>
                  <div className="circle-4"></div>
                  <div className="circle-5"></div>

                  {/* Top Section with Waves */}
                  <div className="card-top">
                    <svg
                      className="top-wave top-wave-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 800" /* Increased height */
                    >
                      <path
                        fill="#FE8200"
                        fillOpacity="0.8"
                        d="M0,300L48,290.7C96,281,192,260,288,245.3C384,230,480,220,576,215.3C672,210,768,230,864,260.7C960,290,1056,340,1152,345.3C1248,351,1344,315,1392,300.7L1440,285L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                      ></path>
                    </svg>

                    <svg
                      className="top-wave top-wave-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 800"
                    >
                      <path
                        fill="#FF9C40"
                        fillOpacity="0.7"
                        d="M0,420L48,400.7C96,380,192,340,288,315.3C384,290,480,275,576,270.3C672,265,768,285,864,315.3C960,345,1056,395,1152,400C1248,405,1344,365,1392,350.7L1440,335L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                      ></path>
                    </svg>

                    <svg
                      className="top-wave top-wave-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 400"
                    >
                      <path
                        fill="#FFBB77"
                        fillOpacity="0.3"
                        d="M0,256L48,234.7C96,213,192,171,288,160C384,149,480,171,576,192C672,213,768,235,864,245.3C960,256,1056,235,1152,213.3C1248,192,1344,171,1392,160L1440,149.3L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                      ></path>
                    </svg>

                    <h4 className="card-title">{card.title}</h4>
                    <div className="price-circle">
                      <p className='cloud-price'>
                        {card.price}
                        <sub>/mo</sub>
                      </p>
                    </div>
                  </div>

                  {/* Content Section */}
                  <div className="card-content justify-content-center">
                    <ul className="features text-start mx-5">
                      {card.features.map((feature, index) => (
                        <li key={index}>
                          <FaCheckCircle className="checkcircle-card" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <button
                className="start-button mb-4"
                onClick={() => handleNavigate(card.link)}
              >
                ORDER NOW <IoIosArrowForward style={{ fontSize: "15px" }} />
              </button>
                  </div>

                  {/* Bottom Section */}
                  <div className="card-bottom">
                    <svg
                      className="bottom-wave"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 400"
                    >
                      <path
                        fill="#FE8200"
                        fillOpacity="0.1"
                        d="M0,64L48,96C96,128,192,192,288,224C384,256,480,256,576,234.7C672,213,768,171,864,149.3C960,128,1056,128,1152,138.7C1248,149,1344,171,1392,181.3L1440,192L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Col>
            ))}
          </Carousel>
        </Row>
      </Row>

      <Row className='text-center justify-content-center cloud-section m-0 pt-4' style={{ backgroundColor: '#FFFBF6' }}>
        <Container className="tabcontainer justify-content-center pb-4" style={{ padding: "20px 30px" }}>
          {/* Header */}
          <Row>
            <Col>
              <h2 className="cloud-hosting-subtitle">Our Hosting Features</h2>
              <p className="text-center">
                Discover powerful hosting features designed for businesses and developers.
              </p>
            </Col>
          </Row>
          <Tabs defaultActiveKey="accountFeatures" id="features-tabs" className=" features-tab-header" justify>
            {/* Tab for Account Features */}
            <Tab eventKey="accountFeatures" title="Account Features" className='features-tab'>
              <ul className="cloud-hosting-list justify-content-center list-unstyled text-start">
                <Row className='justify-content-center' >

                  <Col  xs={6}  lg={3}>
                    <li><FaCheckCircle className="check-icon" /> cPanel Control Panel</li>
                    <li><FaCheckCircle className="check-icon" /> Cloudlinux</li>
                    <li><FaCheckCircle className="check-icon" /> 30 Entry Processes</li>
                  </Col>
                  <Col  xs={6} lg={3}>
                    <li><FaCheckCircle className="check-icon" /> Jetbackup</li>
                    <li><FaCheckCircle className="check-icon" /> Public Cloud</li>
                    <li><FaCheckCircle className="check-icon" /> Unlimited Inodes</li>
                  </Col>
                  <Col xs={6}  lg={3}>
                    <li><FaCheckCircle className="check-icon" /> WP CLI</li>
                    <li><FaCheckCircle className="check-icon" /> Softaculous</li>
                    <li><FaCheckCircle className="check-icon" /> I/O 50 MBPS</li>
                  </Col>
                  <Col xs={6}  lg={3}>
                    <li><FaCheckCircle className="check-icon" /> Imunify360</li>
                    <li><FaCheckCircle className="check-icon" /> SitePad Sitebuilder</li>
                    <li><FaCheckCircle className="check-icon" /> CageFS Security</li>
                  </Col>
                </Row>
              </ul>
            </Tab>

            {/* Tab for Developer Tools */}
            <Tab eventKey="developerTools" title="Developer Tools" className='features-tab' >
              <ul className="cloud-hosting-list justify-content-center list-unstyled text-start">
                <Row className='justify-content-center' >

                  <Col xs={6} lg={3}>
                    <li><FaCheckCircle className="check-icon" />PHP 4.4 to 8.3</li>
                    <li><FaCheckCircle className="check-icon" />Cron Jobs</li>
                    <li><FaCheckCircle className="check-icon" />Mod_security</li>
                    <li><FaCheckCircle className="check-icon" />Python App</li>
                  </Col>
                  <Col xs={6} lg={3}>
                    <li><FaCheckCircle className="check-icon" />Node.js Support</li>
                    <li><FaCheckCircle className="check-icon" />File Manager</li>
                    <li><FaCheckCircle className="check-icon" />IP Address Blocking</li>
                    <li><FaCheckCircle className="check-icon" />Node.js Setup</li>
                  </Col>
                  <Col xs={6} lg={3}>
                    <li><FaCheckCircle className="check-icon" />SSH Access</li>
                    <li><FaCheckCircle className="check-icon" />.htaccess</li>
                    <li><FaCheckCircle className="check-icon" />Git Support</li>
                    <li><FaCheckCircle className="check-icon" />Redis</li>
                  </Col>
                  <Col xs={6} lg={2}>
                    <li><FaCheckCircle className="check-icon" />FTP & SFTP</li>
                    <li><FaCheckCircle className="check-icon" />Mod_rewrite</li>
                    <li><FaCheckCircle className="check-icon" />Memcached</li>
                    <li><FaCheckCircle className="check-icon" />CodeIgniter</li>
                  </Col>
                </Row>
              </ul>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <Row className='text-center justify-content-center cloud-section m-0 pt-3 p-0' style={{ backgroundColor: '#000' }}>
        <Container fluid className="custom-card-container px-4">
          <Row>
            <Col>
              <h2 className="cloud-feature-subtitle">
                Comprehensive<span className="cloud1-subtitle-highlight"> Hosting </span>  Solutions
              </h2>
              <p className="text-center subtitle">
                Reliable, Secure, and Optimized for Maximum Performance.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {projects.map((project, index) => (
              <Col lg={4} md={6} sm={12} key={index} className="d-flex justify-content-center mb-4">
                <Card className="custom-bootstrap-card">
                  <div className="card-image-container">
                    <Card.Img variant="top" src={project.image} className="custom-card-image" />
                    <div className="custom-card-overlay">
                      <Card.Text className="cfeature-custom-card-description">
                        {project.description}
                      </Card.Text>
                    </div>
                  </div>
                  <Card.Body className="custom-card-body">
                    <Card.Title className="custom-card-title m-0">{project.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
      <Row className='text-center justify-content-center cloud-section mt-4 m-0  pt-3 '>
        <ReviewsSection />
      </Row>
    </Container>
  );
};

export default CloudHostingSection;
