import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaGoogle, FaPinterest, FaYoutube, FaRegThumbsUp, FaStripe } from 'react-icons/fa'; // Social Media Icons
import { SiPhonepe, SiVisa } from 'react-icons/si'; // Additional Payment & Trust Icons
import { AiFillDatabase } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import '../MainCSS/footer.css';
import flag1 from '../Images/egypt-flag.png';
import flag2 from '../Images/Senegal-Africa-flag.png';
import flag3 from '../Images/Oman-flag.png';
import flag4 from '../Images/Algeria-flag.png';
import { FaWhatsapp, FaRegUser  } from "react-icons/fa";

const Footer = () => {
    return (
        <footer className="footer mt-3">
            <Container fluid className="footer-container">
                <Row>
                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Domain</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">Register Domain</Link></p>
                            <p><Link to="https://billing.bighostindia.in/cart.php?a=add&domain=transfer" className="link-decoration">Transfer Domain to us</Link></p>
                            <p><Link to="" className="link-decoration">Domain Pricing</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Cloud Hosting</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">Cloud Hosting</Link></p>
                            <p><Link to="" className="link-decoration">Reseller Hosting</Link></p>
                            <p><Link to="" className="link-decoration">VPS Hosting</Link></p>
                            <p><Link to="" className="link-decoration">Email Hosting</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Development</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">Web Development</Link></p>
                            <p><Link to="" className="link-decoration">Web Application Development</Link></p>
                            <p><Link to="" className="link-decoration">eCommerce Development</Link></p>
                            <p><Link to="" className="link-decoration">PWA Development</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Solutions</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">CarDeal Quotation System</Link></p>
                            <p><Link to="" className="link-decoration">Transport Bilty Software</Link></p>
                            <p><Link to="" className="link-decoration">eLearning Management System</Link></p>
                            <p><Link to="" className="link-decoration">Property Management System</Link></p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Industries</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">Technology & IT</Link></p>
                            <p><Link to="" className="link-decoration">Manufacturing & Automation</Link></p>
                            <p><Link to="" className="link-decoration">Real Estate & Construction</Link></p>
                            <p className="hover-color"><Link to="" className="link-decoration">View All</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Company</h3>
                        <div className="footer-list">
                            <p><Link to="" className="link-decoration">About Us</Link></p>
                            <p><Link to="" className="link-decoration">Founder & CEO</Link></p>
                            <p><Link to="" className="link-decoration">Our Technology Stack</Link></p>
                            <p><Link to="" className="link-decoration">Career</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Client Area</h3>
                        <div className="footer-list">
                            <p><Link to="https://billing.bighostindia.in/login" className="link-decoration">Login/Register</Link></p>
                            <p><Link to="" className="link-decoration">Privacy Policy</Link></p>
                            <p><Link to="" className="link-decoration">Terms & Condition</Link></p>
                            <p><Link to="" className="link-decoration">Refunds & Cancellations</Link></p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3} className="footer-section">
                        <h3 className="footer-heading">Corporate Office</h3>
                        <div className="footer-list">
                            <p style={{lineHeight:'18px'}}>Office No. 602, 6th Floor, Roongta <br /> Majestic,Mumbai Agra National Hwy, Kamod Nagar, Indira Nagar, Nashik, Maharashtra 422009</p>
                            <p>Call: +91 8087449376</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="footer-section">
                        <h3 className="footer-heading-last">Our Representatives</h3>
                        <div className="footer-list d-flex flex-wrap justify-content-between">
                            <Col lg={3} xs={12}>
                            <div className="col-12 col-sm-6 col-md-3 mobile-bottom">
                                <p><img src={flag1} alt="Egypt Flag" className="flagimg" />Egypt</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold', marginTop:'-10px' }}><FaRegUser  style={{ fontSize: '19px', marginRight: '10px' }} />Jitendra Mehata</p>
                                <p style={{marginTop:"-10px"}}><FaWhatsapp style={{ fontSize: '19px', color: 'white' }} /> +20 1115559040</p>
                            </div>
                            </Col>
                            <Col lg={3}>
                            <div className="col-12 col-sm-6 col-md-3 mobile-bottom">
                                <p><img src={flag2} alt="Senegal, Africa Flag" className="flagimg" />Senegal, Africa</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' , marginTop:'-10px' }}><FaRegUser  style={{ fontSize: '19px', marginRight: '10px' }} />Oumer Diye</p>
                                <p style={{marginTop:"-10px"}}><FaWhatsapp style={{ fontSize: '19px', color: 'white' }} /> +221 773911575</p>
                            </div>
                            </Col>
                            <Col lg={3}>
                            <div className="col-12 col-sm-6 col-md-3 mobile-bottom">
                                <p><img src={flag3} alt="Sultanate of Oman Flag" className="flagimg" />Sultanate of Oman</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' , marginTop:'-10px' }}><FaRegUser style={{ fontSize: '19px', marginRight: '10px' }} />Sheriff Hood Al-Aidarus</p>
                                <p style={{marginTop:"-10px"}}><FaWhatsapp style={{ fontSize: '19px', color: 'white' }} /> +968 72772274</p>
                            </div>
                            </Col>
                            <Col lg={3}>
                            <div className="col-12 col-sm-6 col-md-3">
                                <p><img src={flag4} alt="Algeria Flag" className="flagimg" />Algeria</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' , marginTop:'-10px' }}><FaRegUser style={{ fontSize: '19px', marginRight: '10px' }} />Ahmed Lazreg</p>
                                <p style={{marginTop:"-10px"}}><FaWhatsapp style={{ fontSize: '19px', color: 'white' }} /> +213 798781332</p>
                            </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>

            <hr />

            {/* Payment and Social Media Logos in a Single Line */}
            <Container fluid className="footer-icons py-3">
                <Row className="align-items-center">
                    {/* Footer Note */}
                    <Col md={6} className=" text-md-start text-center mb-3 mb-md-0">
                        <p className="footer-note mb-0">© 2019-2024 BigHost India Private Limited. All Rights Reserved.</p>
                    </Col>

                    {/* Payment and Social Media Logos */}
                    <Col md={6} className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                        {/* Payment Logos */}
                        <Col className="mb-3 mb-md-0">
                            <div className="payment-logos d-flex flex-wrap justify-content-center">
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <Link to="https://razorpay.me/@bighostindiaprivatelimited" className="link-decoration"><SiPhonepe size={25} color="purple" /></Link>
                                </div>
                                <div className="backicon1 d-flex align-items-center justify-content-center">
                                <Link to="https://razorpay.me/@bighostindiaprivatelimited" className="link-decoration"><FcGoogle size={10} />
                                    <span style={{ color: "black", fontSize: "10px" }}>Pay</span></Link>
                                </div>
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <span style={{ color: "#1F336B", fontWeight: "bold", fontSize: "9px" }}>Pay</span>
                                    <span style={{ color: "#00BCF1", fontWeight: "bold", fontSize: "9px" }}>tm</span>
                                </div>
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <SiVisa size={25} color="#2566AF" />
                                </div>
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 25" width="27" height="27" name="MasterCard">
                                        <circle cx="15" cy="12.5" r="10" fill="#EB001B" />
                                        <circle cx="25" cy="12.5" r="10" fill="orange" />
                                    </svg>
                                </div>
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <FaStripe size={25} color="#6B74D1" />
                                </div>
                                <div className="backicon d-flex align-items-center justify-content-center">
                                    <span style={{ color: "#353787", fontWeight: "bold", fontSize: "10px" }}>RuPay</span>
                                </div>
                            </div>
                        </Col>

                        {/* Social Media Logos */}
                        <Col>
                            <div className="social-media-logos d-flex flex-wrap justify-content-center justify-content-md-end">
                                <Link to="https://www.facebook.com/bighostindiapvtltd" className="link-decoration"><FaFacebook size={20} className="social-icon" /></Link>
                                <Link to="https://www.instagram.com/bighostindia" className="link-decoration"><FaInstagram size={20} className="social-icon" /></Link>
                                <Link to="https://www.linkedin.com/company/bighost-india" className="link-decoration"><FaLinkedin size={20} className="social-icon" /></Link>
                                <Link to="https://www.google.com/maps/place/BigHost+India+Pvt.+Ltd.+-+Cloud+Hosting,+Web+Design+%26+Software+Development/@19.9762765,73.7777927,17z/data=!3m1!4b1!4m6!3m5!1s0x3bdd94b1e4416a43:0x281980179513885!8m2!3d19.9762765!4d73.7777927!16s%2Fg%2F11f667p6fy?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" className="link-decoration"><FaGoogle size={20} className="social-icon" /></Link>
                                <Link to="https://in.pinterest.com/bighostindia" className="link-decoration"><FaPinterest size={20} className="social-icon" /></Link>
                                <Link to="https://www.youtube.com/channel/UCPY8mOuaO5rm8vetpWucqhQ" className="link-decoration"><FaYoutube size={20} className="social-icon" /></Link>
                                <Link to="https://hostadvice.com/hosting-company/bighost-india-private-limited-reviews" className="link-decoration"><AiFillDatabase size={20} className="social-icon" /></Link>
                                <Link to="https://hostadvice.com/hosting-company/bighost-india-private-limited-reviews" className="link-decoration"><FaRegThumbsUp size={20} className="social-icon" /></Link>
                            </div>
                        </Col>
                    </Col>

                </Row>
            </Container>

        </footer>
    );
};

export default Footer;
