import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./MainPages/Header.jsx";
import Footer from "./MainPages/Footer.jsx";
import FrontPage from "./MainPages/FrontPage.jsx";
import CloudHostingService from "./MainPages/CloudHostingService.jsx";


const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/cloud-hosting-service" element={<CloudHostingService />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
