// import React, { useState } from "react";
// import "../MainCSS/domain.css";
// import { BiPhoneCall } from "react-icons/bi";

// const Domain = () => {
//     const [selectedSection, setSelectedSection] = useState("Register Your Domain");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light ">
//                     <p className="mainheading mb-2">Domain Services</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">Providing Comprehensive Solutions for Your Digital Landscape</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "Register Your Domain" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Register Your Domain")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Register Your Domain
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Transfer Domain To Us" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Transfer Domain To Us")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Transfer Domain To Us
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Domain Pricing" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Domain Pricing")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Domain Pricing
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "Register Your Domain" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Register Your Domain</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Take the first step toward building your online identity by registering your domain today. With a wide
//                                 selection of domain extensions, seamless registration, and quick setup, establishing your brand online has
//                                 never been easier.
//                             </p>
//                             <div className="d-flex justify-content-start align-items-start mt-4">
//                                 {/* <input
//                                     type="text"
//                                     className="form-control searchbar"
//                                     placeholder="Find Your Perfect Domain"
//                                 />
//                                 <button className="btn btn-search searchbtn">Search</button>
//                                 <form action="https://billing.bighostindia.in/cart.php?a=add&domain=register" method="post">
//                                     <input type="text" name="query" size="20" placeholder="Find Your Domain"/>
//                                     <input type="submit" value="Submit" />
//                                 </form> */}

//                                 <form
//                                     action="https://billing.bighostindia.in/cart.php?a=add&domain=register"
//                                     method="post"
//                                     className="d-flex"
//                                 >
//                                     <input
//                                         type="text"
//                                         name="query"
//                                         className="form-control searchbar"
//                                         placeholder="Find Your Perfect Domain"
//                                     />
//                                     <button type="submit" className="btn btn-search searchbtn">
//                                         Search
//                                     </button>
//                                 </form>

//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Transfer Domain To Us" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Transfer Domain To Us</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Switch to a better domain management experience by transferring your domain to us. Enjoy uninterrupted
//                                 service, improved security, and expert support every step of the way, ensuring a smooth and reliable
//                                 transfer process.
//                             </p>
//                         </div>
//                     )}

//                     {selectedSection === "Domain Pricing" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Domain Pricing</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Discover competitive and transparent pricing plans for all your domain needs. From affordable registration
//                                 to renewal and transfer rates, we offer flexibility and clarity to help you make the best choice for your
//                                 online presence.
//                             </p>
//                         </div>
//                     )}

//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Domain;


import React, { useState } from "react";
import { Container, Row, Col, Button, Form, ListGroup } from "react-bootstrap";
import "../MainCSS/domain.css";
import { BiPhoneCall } from "react-icons/bi";
import { Link } from "react-router-dom";  // Importing Link from react-router-dom

const Domain = () => {
    const [selectedSection, setSelectedSection] = useState("Register Your Domain");

    return (
        <Container className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar (col-md-4) */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">Domain Services</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">Providing Comprehensive Solutions for Your Digital Landscape</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Register Your Domain"}
                                    onMouseEnter={() => setSelectedSection("Register Your Domain")}
                                >
                                    <Link to="" className="no-text-decoration">Register Your Domain</Link>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Transfer Domain To Us"}
                                    onMouseEnter={() => setSelectedSection("Transfer Domain To Us")}
                                >
                                    <Link to="https://billing.bighostindia.in/cart.php?a=add&domain=transfer" className="no-text-decoration">Transfer Domain To Us</Link>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Domain Pricing"}
                                    onMouseEnter={() => setSelectedSection("Domain Pricing")}
                                >
                                    <Link to="" className="no-text-decoration">Domain Pricing</Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content (col-md-8) */}
                <Col md={8} className="bg-white">
                    {selectedSection === "Register Your Domain" && (
                        <Row>
                            <Col className="w-100">
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col>
                                        <p className="mainheading mb-2">Register Your Domain</p>
                                    </Col>
                                    <Col className="text-end">
                                        <Link to="" className="btn btn-view">View All</Link> {/* Link added here */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="headingborder"></p>
                                        <p className="text">
                                            Take the first step toward building your online identity by registering your domain today. With a wide
                                            selection of domain extensions, seamless registration, and quick setup, establishing your brand online has
                                            never been easier.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Form
                                            action="https://billing.bighostindia.in/cart.php?a=add&domain=register"
                                            method="post"
                                            className="d-flex"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="query"
                                                className="searchbar"
                                                placeholder="Find Your Perfect Domain"
                                            />
                                            <Button type="submit" className="btn btn-search searchbtn">
                                                Search
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}

                    {selectedSection === "Transfer Domain To Us" && (
                        <Row className="pb-5 w-100">
                            <Col>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col>
                                        <p className="mainheading mb-2">Transfer Domain To Us</p>
                                    </Col>
                                    <Col className="text-end">
                                        <Link to="https://billing.bighostindia.in/cart.php?a=add&domain=transfer" className="btn btn-view">View All</Link> {/* Link added here */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="headingborder"></p>
                                        <p className="text">
                                            Switch to a better domain management experience by transferring your domain to us. Enjoy uninterrupted
                                            service, improved security, and expert support every step of the way, ensuring a smooth and reliable
                                            transfer process.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}

                    {selectedSection === "Domain Pricing" && (
                        <Row className="pb-5 w-100">
                            <Col>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col>
                                        <p className="mainheading mb-2">Domain Pricing</p>
                                    </Col>
                                    <Col className="text-end">
                                        <Link to="/domain-pricing" className="btn btn-view">View All</Link> {/* Link added here */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="headingborder"></p>
                                        <p className="text">
                                            Discover competitive and transparent pricing plans for all your domain needs. From affordable registration
                                            to renewal and transfer rates, we offer flexibility and clarity to help you make the best choice for your
                                            online presence.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}

                    {/* Footer for col-md-8 */}
                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col>
                            <p className="text-muted text-end">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Domain;
