import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../MainCSS/frontpage.css';
import cimg1 from '../Images/software_development_services1.jpg';
import cimg2 from '../Images/fast_secure_stable_cloud_hosting.jpg';
import { FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import Card from '../MainPages/Card.jsx';
import Development from '../MainPages/DevelopmentServices.jsx';
import Technology from '../MainPages/Technology.jsx';
import PostSection from '../MainPages/PostSection.jsx';
import Reviews from '../MainPages/Reviews.jsx'
import FeaturesCard from '../MainPages/FeaturesCards.jsx';

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Automatically switch images every 3 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleBulletClick = (index) => {
    setCurrentIndex(index);
  };

  const slides = [
    {
      img: cimg1,
      title: 'Software Development Services',
      description:
        'We deliver tailored software solutions to tackle your business challenges, ensuring high performance, scalability, and a competitive edge. Streamline operations, cut costs, and adapt effortlessly to market changes with our expertise.',
    },
    {
      img: cimg2,
      title: 'Cloud Web Hosting',
      // title: (<>Web Application<br />Development</>),
      description:
        'BigHost delivers top-notch cloud web hosting solutions to power your online success. With managed hosting, we ensure your website is secure, reliable, and high-performing. Enjoy FREE SSL, Domain, Website Transfer, and Optimization with every plan. Start building your online presence today!',
    },   
  ];

  return (
    <>
      <div className="carousel">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-image ${currentIndex === index ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide.img})` }}
          >
            <div className="overlay">
              <Container fluid className="container-center">
                <Row>
                  <Col md={8}>
                    <div className="text-content">
                      <h1>{slide.title}</h1>
                      <p className='slide-description'>{slide.description}</p>                      
                      <button className="slider-read-more-btn">
                        Read More
                        <FaArrowRight style={{ fontSize: '12px', marginLeft: '5px' }} />
                      </button>
                    </div>
                  </Col>                  
                </Row>
              </Container>
            </div>
          </div>
        ))}

        {/* Pagination Bullets */}
        <div className="pagination">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`bullet ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleBulletClick(index)}
            />
          ))}
        </div>
      </div>
      <div className='price-card-section'>
          <Card/>
      </div>
      <div className='px-3'>
        <Reviews/>
      </div>
      <div>
        <Development/>
      </div>
      <div>
        <FeaturesCard/>
      </div>
      <div>
        <Technology/>
      </div>
      <div>
        <PostSection/>
      </div>
    </>
  );
};

export default ImageCarousel;
