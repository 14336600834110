// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle } from "react-icons/fa";

// const CloudHosting = () => {
//     const [selectedSection, setSelectedSection] = useState("Cloud Hosting");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">Cloud Services</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">Reliable, Scalable, and Secure Cloud Hosting Solutions</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "Cloud Hosting" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Cloud Hosting")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Cloud Hosting
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Shared Hosting" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Shared Hosting")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Shared Hosting
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Reseller Hosting" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Reseller Hosting")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Reseller Hosting
//                         </li>
//                         {/* <li
//                             className={`list-group-item ${selectedSection === "VPS Hosting" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("VPS Hosting")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             VPS Hosting
//                         </li> */}
//                         <li
//                             className={`list-group-item ${selectedSection === "Email Hosting" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Email Hosting")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Email Hosting
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "Cloud Hosting" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Cloud Hosting</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Businesses and individuals around the world turn to BigHost for our superior cloud web hosting solutions.
//                             </p>
//                             <p>Starting at <strong style={{ color: '#fe8200' }}>Rs.119/mo</strong> including:</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />FREE SSL</p>
//                                     <p><FaCheckCircle className="checkcircle" />FREE Website Migration</p>
//                                     <p><FaCheckCircle className="checkcircle" />FREE Daily Backups</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Imunify360 Security</p>
//                                     <p><FaCheckCircle className="checkcircle" />CageFS Hack Protection</p>
//                                     <p><FaCheckCircle className="checkcircle" />WordPress LiteSpeed Cache</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Shared Hosting" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Shared Hosting</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                             Businesses and individuals trust BigHost for reliable and affordable shared hosting solutions.</p>
//                             <p>Starting at <strong style={{ color: '#fe8200' }}>Rs.349/yr</strong> including:</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Free SSL</p>
//                                     <p><FaCheckCircle className="checkcircle" />Imunify360 Security</p>
//                                     <p><FaCheckCircle className="checkcircle" />FREE Backups</p>                                    
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />CageFS Hack Protection</p>
//                                     <p><FaCheckCircle className="checkcircle" />WHM Access and cPanel accoutns</p>
//                                     <p><FaCheckCircle className="checkcircle" />WordPress LiteSpeed Cache</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}


//                     {selectedSection === "Reseller Hosting" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Reseller Hosting</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Get our powerful white labeled reseller packages. Your customers will love their faster sites, hosted on our servers that are up to 20X faster MVMe.</p>
//                             <p>Starting at <strong style={{ color: '#fe8200' }}>Rs.749/mo</strong> including:</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />FREE SSL for all websites</p>
//                                     <p><FaCheckCircle className="checkcircle" />Imunify360 Security</p>
//                                     <p><FaCheckCircle className="checkcircle" />FREE Backups</p>
//                                     <p><FaCheckCircle className="checkcircle" />WordPress LiteSpeed Cache</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />CageFS Hack Protection</p>
//                                     <p><FaCheckCircle className="checkcircle" />WHM Access and cPanel accoutns</p>
//                                     <p><FaCheckCircle className="checkcircle" />FREE White-Label + Personal Nameservers</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* {selectedSection === "VPS Hosting" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">VPS Hosting</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{fontWeight:"bold"}}>Cloud Linux VPS</h5>
//                             <p className="text">
//                             Experience the speed and reliability you deserve. All our virtual servers come with SSD storage and unlimited transfer as standard.</p>

//                         </div>
//                     )} */}

//                     {selectedSection === "Email Hosting" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Email Hosting</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{ fontWeight: "bold" }}>Professional Business Email Hosting</h5>
//                             <p className="text">
//                                 Give your business a professional makeover with email@yourbrand.com, Block nearly 100% of viruses, malware and spam before they ever reach your inbox</p>
//                         </div>
//                     )}


//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CloudHosting;


import React, { useState } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import "../MainCSS/domain.css";
import { BiPhoneCall } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CloudHosting = () => {
    const navigate = useNavigate();
    const [selectedSection, setSelectedSection] = useState("Cloud Hosting");

    return (
        <Container fluid className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">Cloud Services</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">Reliable, Scalable, and Secure Cloud Hosting Solutions</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Cloud Hosting"}
                                    onMouseEnter={() => setSelectedSection("Cloud Hosting")}
                                    onClick={() => navigate("/cloud-hosting-service")}
                                >
                                    Cloud Hosting
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Shared Hosting"}
                                    onMouseEnter={() => setSelectedSection("Shared Hosting")}
                                >
                                    Shared Hosting
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Reseller Hosting"}
                                    onMouseEnter={() => setSelectedSection("Reseller Hosting")}
                                >
                                    Reseller Hosting
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Email Hosting"}
                                    onMouseEnter={() => setSelectedSection("Email Hosting")}
                                >
                                    Email Hosting
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content */}
                <Col md={8} className="bg-white">
                    {selectedSection === "Cloud Hosting" && (
                        <Container>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Cloud Hosting</p>
                                </Col>
                                <Col className="text-end">
                                    <button className="btn btn-view">View All</button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Businesses and individuals around the world turn to BigHost for our superior cloud web hosting solutions.
                            </p>
                            <p>
                                Starting at <strong style={{ color: "#fe8200" }}>Rs.119/mo</strong> including:
                            </p>
                            <Row>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> FREE SSL</p>
                                    <p><FaCheckCircle className="checkcircle" /> FREE Website Migration</p>
                                    <p><FaCheckCircle className="checkcircle" /> FREE Daily Backups</p>
                                </Col>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> Imunify360 Security</p>
                                    <p><FaCheckCircle className="checkcircle" /> CageFS Hack Protection</p>
                                    <p><FaCheckCircle className="checkcircle" /> WordPress LiteSpeed Cache</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Shared Hosting" && (
                        <Container>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Shared Hosting</p>
                                </Col>
                                <Col className="text-end">
                                    <button className="btn btn-view"    onClick={() => navigate("/cloud-hosting-service")}>View All</button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Businesses and individuals trust BigHost for reliable and affordable shared hosting solutions.
                            </p>
                            <p>
                                Starting at <strong style={{ color: "#fe8200" }}>Rs.349/yr</strong> including:
                            </p>
                            <Row>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> Free SSL</p>
                                    <p><FaCheckCircle className="checkcircle" /> Imunify360 Security</p>
                                    <p><FaCheckCircle className="checkcircle" /> FREE Backups</p>
                                </Col>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> CageFS Hack Protection</p>
                                    <p><FaCheckCircle className="checkcircle" /> WHM Access and cPanel accounts</p>
                                    <p><FaCheckCircle className="checkcircle" /> WordPress LiteSpeed Cache</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Reseller Hosting" && (
                        <Container>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Reseller Hosting</p>
                                </Col>
                                <Col className="text-end">
                                    <button className="btn btn-view">View All</button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Get our powerful white-labeled reseller packages. Your customers will love their faster sites, hosted on our servers that are up to 20X faster NVMe.
                            </p>
                            <p>
                                Starting at <strong style={{ color: "#fe8200" }}>Rs.749/mo</strong> including:
                            </p>
                            <Row>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> FREE SSL for all websites</p>
                                    <p><FaCheckCircle className="checkcircle" /> FREE Backups</p>
                                    <p><FaCheckCircle className="checkcircle" /> WordPress LiteSpeed Cache</p>
                                </Col>
                                <Col xs={6}>
                                    <p><FaCheckCircle className="checkcircle" /> CageFS Hack Protection</p>
                                    <p><FaCheckCircle className="checkcircle" /> WHM Access and cPanel accounts</p>
                                    <p><FaCheckCircle className="checkcircle" /> FREE White-Label + Personal Nameservers</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Email Hosting" && (
                        <Container>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Email Hosting</p>
                                </Col>
                                <Col className="text-end">
                                    <button className="btn btn-view">View All</button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h5 style={{ fontWeight: "bold" }}>Professional Business Email Hosting</h5>
                            <p className="text">
                                Give your business a professional makeover with email@yourbrand.com. Block nearly 100% of viruses, malware, and spam before they ever reach your inbox.
                            </p>
                        </Container>
                    )}

                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col>
                            <p className="text-muted text-end">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    );
};

export default CloudHosting;
