// import React from 'react';
// import { Card, Row, Col, Container } from 'react-bootstrap';
// import '../MainCSS/FeaturesCards.css';
// // import img1 from '../Images/Cloud-Technology-Provider-BigHost-India.png';
// // import img2 from '../Images/Only-Cloud-Web-Hosting-BigHost-India.png';

// const projects = [
//   {
//     title: 'Scoping',
//     // image: img1,
//     description: 'Define the project’s boundaries, goals, and deliverables to ensure clarity and alignment among all stakeholders. Proper scoping sets expectations and helps identify potential issues early on. It establishes a clear roadmap for the project’s success, ensuring everyone is on the same page.',
//   },
//   {
//     title: 'Resource Planning',
//     // image: img2,
//     description: 'Allocate resources like personnel, tools, and time effectively to meet project needs. It ensures that the right resources are available at the right time to avoid delays. Proper resource planning optimizes efficiency and prevents overburdening team members.',
//   },
//   {
//     title: 'Cost Estimation',
//     // image: img1,
//     description: 'Forecast expenses by evaluating the project’s scope and requirements. Accurate cost estimation helps secure funding and keeps the project financially viable. It also provides a clear budget that allows for better decision-making and cost control throughout the project.',
//   },
//   {
//     title: 'Risk Management',
//     // image: img2,
//     description: 'Identify potential risks and plan strategies to mitigate them. Proactive risk management ensures that the project remains on track and minimizes the impact of any unexpected challenges. It helps in making adjustments early to avoid major disruptions.',
//   },
//   {
//     title: 'Change Management',
//     // image: img1,
//     description: 'Implement structured processes to address changes in project scope, timelines, or requirements. Change management reduces resistance and ensures that all stakeholders are aligned with the adjustments. It keeps the project flexible without compromising its goals.',
//   },
//   {
//     title: 'Success Measurement',
//     // image: img2,
//     description: 'Establish clear metrics and KPIs to track the project’s progress and outcomes. Success measurement ensures that the project stays focused on its objectives and goals. Regular assessments help identify improvements and ensure the project’s success.',
//   },
//   {
//     title: 'Project Reporting',
//     // image: img1,
//     description: 'Deliver regular updates to stakeholders regarding the project’s progress and challenges. Transparent reporting fosters trust and keeps all parties informed about the project’s current state. It helps in decision-making and ensures alignment with the overall objectives.',
//   },
//   {
//     title: 'Collaboration',
//     // image: img2,
//     description: 'Foster open communication and teamwork among all stakeholders to ensure efficiency. Collaboration enhances problem-solving, promotes innovation, and creates a sense of ownership among team members. It drives collective success by ensuring that everyone is working towards the same goal.',
//   },
// ];

// const CustomHoverCard = () => {
//   return (
//     <Container fluid className="custom-card-container">
//       <Row>
//         <Col>
//           <h1 className="feature-subtitle">Secure the<span style={{color:'#fe8200'}}> Success </span>of Your IT Initiative</h1>
//           <p className="text-center subtitle">
//           Ensure the success of your IT initiatives with expert strategies for streamlined planning, efficient execution, and seamless collaboration, driving measurable results and sustainable growth.
//           </p>
//         </Col>
//       </Row>
//       <Row className="justify-content-center">
//         {projects.map((project, index) => (
//           <Col lg={3} md={6} sm={12} key={index} className="d-flex justify-content-center mb-4">
//             <Card className="custom-bootstrap-card">  
//               <div className="card-image-container">
//                 <Card.Img variant="top" src={project.image} className="custom-card-image" />
//                 <div className="custom-card-overlay">
//                   <Card.Text className="custom-card-description">
//                     {project.description}
//                   </Card.Text>
//                 </div>
//               </div>
//               <Card.Body className="custom-card-body">
//                 <Card.Title className="custom-card-title m-0">{project.title}</Card.Title>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// };

// export default CustomHoverCard;



import React, { useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../MainCSS/FeaturesCards.css';
import feature1 from '../Images/features/Scoping.png';
import feature2 from '../Images/features/Resource palnning.png'
import feature3 from '../Images/features/Cost estimation.png';
import feature4 from '../Images/features/Risk management.png';
import feature5 from '../Images/features/Change management.png';
import feature6 from '../Images/features/Success measurement.png';
import feature7 from '../Images/features/Project reporting.png';
import feature8 from '../Images/features/Collaboration.png';

const projects = [
  {
    title: 'Scoping',
    image: feature1,
    description: 'Define the project’s boundaries, goals, and deliverables to ensure clarity and alignment among all stakeholders. Proper scoping sets expectations and helps identify potential issues early on. It establishes a clear roadmap for the project’s success, ensuring everyone is on the same page.',
  },
  {
    title: 'Resource Planning',
    image: feature2,
    description: 'Allocate resources like personnel, tools, and time effectively to meet project needs. It ensures that the right resources are available at the right time to avoid delays. Proper resource planning optimizes efficiency and prevents overburdening team members.',
  },
  {
    title: 'Cost Estimation',
    image: feature3,
    description: 'Forecast expenses by evaluating the project’s scope and requirements. Accurate cost estimation helps secure funding and keeps the project financially viable. It also provides a clear budget that allows for better decision-making and cost control throughout the project.',
  },
  {
    title: 'Risk Management',
    image: feature4,
    description: 'Identify potential risks and plan strategies to mitigate them. Proactive risk management ensures that the project remains on track and minimizes the impact of any unexpected challenges. It helps in making adjustments early to avoid major disruptions.',
  },
  {
    title: 'Change Management',
    image: feature5,
    description: 'Implement structured processes to address changes in project scope, timelines, or requirements. Change management reduces resistance and ensures that all stakeholders are aligned with the adjustments. It keeps the project flexible without compromising its goals.',
  },
  {
    title: 'Success Measurement',
    image: feature6,
    description: 'Establish clear metrics and KPIs to track the project’s progress and outcomes. Success measurement ensures that the project stays focused on its objectives and goals. Regular assessments help identify improvements and ensure the project’s success.',
  },
  {
    title: 'Project Reporting',
    image: feature7,
    description: 'Deliver regular updates to stakeholders regarding the project’s progress and challenges. Transparent reporting fosters trust and keeps all parties informed about the project’s current state. It helps in decision-making and ensures alignment with the overall objectives.',
  },
  {
    title: 'Collaboration',
    image: feature8,
    description: 'Foster open communication and teamwork among all stakeholders to ensure efficiency. Collaboration enhances problem-solving, promotes innovation, and creates a sense of ownership among team members. It drives collective success by ensuring that everyone is working towards the same goal.',
  },
];

const CustomHoverCard = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Track active slide

  const handleDotClick = (index) => {
    setActiveIndex(index); // Update activeIndex on dot click
  };

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 5 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <Container fluid className="feature-custom-card-container pb-5">
      <Row className="mt-4 mb-4">
        <Col>
          <h1 className="feature-subtitle mb-3">
            Secure the Success of Your IT Initiative
          </h1>
          <p className="text-center subtitle-para mb-4" style={{padding:'0 15%'}}>
            Ensure the success of your IT initiatives with expert strategies for streamlined planning, efficient execution, and seamless collaboration, driving measurable results and sustainable growth.
          </p>
        </Col>
      </Row>

      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        arrows={false}
        pauseOnHover={false}
        activeIndex={activeIndex}
        onChange={(previousIndex, nextIndex) => setActiveIndex(nextIndex)} // Track index change
      >
        {projects.map((project, index) => (
          <div key={index} className="custom-carousel-item">
            <Card className="custom-card">
              <div className="card-image-container">
                <Card.Img variant="top" src={project.image} className="custom-card-image" />
                <div className="custom-card-overlay">
                  <Card.Text className="feature-custom-card-description">
                    {project.description}
                  </Card.Text>
                </div>
              </div>
              <Card.Body className="custom-card-body">
                <Card.Title className="custom-card-title m-0">{project.title}</Card.Title>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Carousel>

      {/* Custom Dots */}
      <div className="custom-dots">
        {projects.map((_, index) => (
          <div
            key={index}
            className={`dot ${activeIndex === index ? 'active' : ''}`} // Mark active dot
            onClick={() => handleDotClick(index)} // Update active dot on click
          />
        ))}
      </div>
    </Container>
  );
};

export default CustomHoverCard;
