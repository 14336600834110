// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle } from "react-icons/fa";

// const Application = () => {
//     const [selectedSection, setSelectedSection] = useState("Web Development");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">Application Development</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">Future-Proof Application Development for Growing Enterprises</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "Web Development" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Web Development")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Web Development
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Web Application Development" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Web Application Development")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Web Application Development
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "eCommerce Development" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("eCommerce Development")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             eCommerce Development
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "PWA Development" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("PWA Development")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             PWA Development
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "Web Development" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Web Development</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 We design responsive, SEO-optimized, and user-friendly websites to establish a strong digital presence and boost engagement.</p>
//                             <p><FaCheckCircle className="checkcircle" />Mobile-first and responsive designs</p>
//                             <p><FaCheckCircle className="checkcircle" />SEO-friendly architecture</p>
//                             <p><FaCheckCircle className="checkcircle" />Tailored to your business needs</p>
//                         </div>
//                     )}

//                     {selectedSection === "Web Application Development" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Web Application Development</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Our team develops custom, scalable, and secure web applications to streamline your processes and enhance productivity.</p>

//                             <p><FaCheckCircle className="checkcircle" />Scalable solutions for growing businesses</p>
//                             <p><FaCheckCircle className="checkcircle" />Scalable solutions for growing businesses</p>
//                             <p><FaCheckCircle className="checkcircle" />Intuitive user interfaces</p>
//                         </div>
//                     )}

//                     {selectedSection === "eCommerce Development" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">eCommerce Development</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Cloud Linux VPS</h5> */}
//                             <p className="text">
//                                 We create secure and feature-rich eCommerce platforms that drive conversions and provide an exceptional shopping experience.</p>
//                             <p><FaCheckCircle className="checkcircle" />User-friendly storefronts</p>
//                             <p><FaCheckCircle className="checkcircle" />Integration with payment gateways</p>
//                             <p><FaCheckCircle className="checkcircle" />Inventory and order management systems</p>
//                         </div>
//                     )}

//                     {selectedSection === "PWA Development" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">PWA Development</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 Build fast, reliable, and engaging Progressive Web Apps (PWAs) for a superior user experience across devices.</p>
//                             <p><FaCheckCircle className="checkcircle" />Offline capabilities</p>
//                             <p><FaCheckCircle className="checkcircle" />App-like performance</p>
//                             <p><FaCheckCircle className="checkcircle" />App-like performance</p>
//                         </div>
//                     )}


//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Application;



import React, { useState } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import '../MainCSS/domain.css';
import { BiPhoneCall } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";

const Application = () => {
    const [selectedSection, setSelectedSection] = useState("Web Development");

    return (
        <Container fluid className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">Application Development</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">Future-Proof Application Development for Growing Enterprises</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Web Development"}
                                    onMouseEnter={() => setSelectedSection("Web Development")}
                                >
                                    Web Development
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Web Application Development"}
                                    onMouseEnter={() => setSelectedSection("Web Application Development")}
                                >
                                    Web Application Development
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "eCommerce Development"}
                                    onMouseEnter={() => setSelectedSection("eCommerce Development")}
                                >
                                    eCommerce Development
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "PWA Development"}
                                    onMouseEnter={() => setSelectedSection("PWA Development")}
                                >
                                    PWA Development
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    {/* Footer for Left Sidebar */}
                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content */}
                <Col md={8} className="bg-white">
                    {selectedSection === "Web Development" && (
                        <Container fluid>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Web Development</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="view">View All</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="headingborder"></p>
                                    <p className="text">
                                        We design responsive, SEO-optimized, and user-friendly websites to establish a strong digital presence and boost engagement.
                                    </p>
                                    <p><FaCheckCircle className="checkcircle" />Mobile-first and responsive designs</p>
                                    <p><FaCheckCircle className="checkcircle" />SEO-friendly architecture</p>
                                    <p><FaCheckCircle className="checkcircle" />Tailored to your business needs</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Web Application Development" && (
                        <Container fluid>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Web Application Development</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="view">View All</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="headingborder"></p>
                                    <p className="text">
                                        Our team develops custom, scalable, and secure web applications to streamline your processes and enhance productivity.
                                    </p>
                                    <p><FaCheckCircle className="checkcircle" />Scalable solutions for growing businesses</p>
                                    <p><FaCheckCircle className="checkcircle" />Intuitive user interfaces</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "eCommerce Development" && (
                        <Container fluid>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">eCommerce Development</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="view">View All</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="headingborder"></p>
                                    <p className="text">
                                        We create secure and feature-rich eCommerce platforms that drive conversions and provide an exceptional shopping experience.
                                    </p>
                                    <p><FaCheckCircle className="checkcircle" />User-friendly storefronts</p>
                                    <p><FaCheckCircle className="checkcircle" />Integration with payment gateways</p>
                                    <p><FaCheckCircle className="checkcircle" />Inventory and order management systems</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "PWA Development" && (
                        <Container fluid>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">PWA Development</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="view">View All</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="headingborder"></p>
                                    <p className="text">
                                        Build fast, reliable, and engaging Progressive Web Apps (PWAs) for a superior user experience across devices.
                                    </p>
                                    <p><FaCheckCircle className="checkcircle" />Offline capabilities</p>
                                    <p><FaCheckCircle className="checkcircle" />App-like performance</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {/* Footer for Right Content */}
                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Application;
