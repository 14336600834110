// import React from "react";
// import "../MainCSS/card.css";
// import { FaCheckCircle } from "react-icons/fa";
// import { IoIosArrowForward } from "react-icons/io";

// const PricingCard = () => {

//   // Pricing card data
//   const pricingData = [
//     {
//       id: 1,
//       title: "Shared Hosting",
//       price: "₹29",
//       features: [
//         "100 MB NVMe",
//         "10 GB Bandwidth",
//         "2 Email Accounts",
//         "2 Subdomains"
//       ]
//     },
//     {
//       id: 2,
//       title: "Cloud Hosting",
//       price: "₹149",
//       features: [
//         "Unlimited Bandwidth",
//         "Free Daily Backups",
//         "Free Migrations Support",
//         "24/7 Tech Support"
//       ]
//     },
//     {
//       id: 3,
//       title: "Reseller Hosting",
//       price: "₹749",
//       features: [
//         "15-200 cPanel accounts",
//         "2GB RAM per cPanel",
//         "Free Migrations Support",
//         "24/7 Tech Support"
//       ]
//     },
//   ];
//   return (
//     <div className="pricing-card-container d-flex justify-content-center mt-5 mb-5">
//       {pricingData.map((card) => (
//         <div key={card.id} className="pricing-card mx-4">
//           {/* Background Circles */}
//           <div className="circle-1"></div>
//           <div className="circle-2"></div>
//           <div className="circle-3"></div>
//           <div className="circle-4"></div>
//           <div className="circle-5"></div>

//           {/* Top Section with Waves */}
//           <div className="card-top">
//             {/* Multiple Waves */}
//             <svg
//               className="top-wave top-wave-1"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 1440 600"
//             >
//               <path
//                 fill="#FE8200"
//                 fillOpacity="0.8"
//                 d="M0,200L48,194.7C96,189,192,179,288,173.3C384,168,480,168,576,162.7C672,157,768,147,864,157.3C960,168,1056,200,1152,205.3C1248,211,1344,189,1392,178.7L1440,168L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//               ></path>
//             </svg>


//             <svg
//               className="top-wave top-wave-2"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 1440 500"
//             >
//               <path
//                 fill="#FF9C40"
//                 fillOpacity="0.7"
//                 d="M0,320L48,307.3C96,294,192,264,288,245.3C384,226,480,218,576,213.3C672,208,768,221,864,245.3C960,269,1056,309,1152,311C1248,313,1344,269,1392,250.7L1440,232L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//               ></path>
//             </svg>

//             <svg
//               className="top-wave top-wave-3"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 1440 400" // Increased height of the viewBox
//             >
//               <path
//                 fill="#FFBB77"
//                 fillOpacity="0.3"
//                 d="M0,256L48,234.7C96,213,192,171,288,160C384,149,480,171,576,192C672,213,768,235,864,245.3C960,256,1056,235,1152,213.3C1248,192,1344,171,1392,160L1440,149.3L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
//               ></path>
//             </svg>

//             <h4 className="card-title">{card.title}</h4>
//             {/* <p className="card-tag">dffhasdgshdfdbhsdbhffhs</p> */}
//             <div className="price-circle">
//               <p className='price'>{card.price}</p>
//               <sub>/mo</sub>
//             </div>
//           </div>

//           {/* Content Section */}
//           <div className="card-content">
            
//             <ul className="features text-start mx-5">
//               {card.features.map((feature, index) => (
//                 <li key={index}>
//                   <FaCheckCircle className="checkcircle-card" />
//                   {feature}
//                 </li>
//               ))}
//             </ul>
//             <button className="start-button mb-4">
//               ORDER NOW <IoIosArrowForward style={{ fontSize: "15px" }} />
//             </button>
//           </div>

//           {/* Bottom Section */}
//           <div className="card-bottom">
//             <svg
//               className="bottom-wave"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 1440 400"
//             >
//               <path
//                 fill="#FE8200"
//                 fillOpacity="0.1"
//                 d="M0,64L48,96C96,128,192,192,288,224C384,256,480,256,576,234.7C672,213,768,171,864,149.3C960,128,1056,128,1152,138.7C1248,149,1344,171,1392,181.3L1440,192L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
//               ></path>
//             </svg>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default PricingCard;




import React, { useState, useEffect } from "react";
import { Row, Col,  } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../MainCSS/card.css";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const PricingCard = () => {
  // Pricing card data
  const navigate = useNavigate();
  
  const pricingData = [
    {
      id: 1,
      title: "Shared Hosting",
      price: "₹29",
      features: ["100 MB NVMe", "10 GB Bandwidth", "2 Email Accounts", "2 Subdomains"],
      link: "https://billing.bighostindia.in/store/linux-shared-hosting",
    },
    {
      id: 2,
      title: "Cloud Hosting",
      price: "₹149",
      features: [
        "Unlimited Bandwidth",
        "Free Daily Backups",
        "Free Migrations Support",
        "24/7 Tech Support",
      ],
      link: "https://billing.bighostindia.in/store/cloud-hosting",
    },
    {
      id: 3,
      title: "Reseller Hosting",
      price: "₹749",
      features: [
        "15-200 cPanel accounts",
        "2GB RAM per cPanel",
        "Free Migrations Support",
        "24/7 Tech Support",
      ],
      link: "https://billing.bighostindia.in/store/cloud-reseller-hosting",
    },
  ];
  const [carouselSettings, setCarouselSettings] = useState({
    infinite: false,
    autoPlay: false,
    arrows: false,
    showDots: false,
  });

  // Detect screen size and adjust carousel settings
  useEffect(() => {
    const updateCarouselSettings = () => {
      if (window.innerWidth < 768) {
        setCarouselSettings({
          infinite: true,
          autoPlay: true,
          arrows: false,
          showDots: true,
        });
      } else {
        setCarouselSettings({
          infinite: false,
          autoPlay: false,
          arrows: false,
          showDots: false,
        });
      }
    };

    updateCarouselSettings();
    window.addEventListener("resize", updateCarouselSettings);
    return () => window.removeEventListener("resize", updateCarouselSettings);
  }, []);

  const Cardresponsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1200 }, items: 3 },
    desktop: { breakpoint: { max: 1200, min: 992 }, items: 2 },
    tablet: { breakpoint: { max: 992, min: 768 }, items: 1 },
    mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  };

  const handleNavigate = (link) => {
    // Option 2: Use window.location.href (uncomment to test)
    window.location.href = link;
  };

  return (
    <div className="pricing-section px-3 justify-content-center mt-5 mb-5">
       <Row className="reviews-header">
                <Col>
                    <h2 className="mb-2">Pick Your Perfect Plan</h2>
                    <p style={{padding:'0 15%'}}>
                    Experience the reliability of crash-free, steady hosting with 99.99% uptime. Our fast, secure, and stable cPanel hosting solutions ensure your website performs seamlessly. 
                    </p>
                </Col>
            </Row>
      <Carousel
        responsive={Cardresponsive}
        infinite={carouselSettings.infinite}
        autoPlay={carouselSettings.autoPlay}
        arrows={carouselSettings.arrows}
        showDots={carouselSettings.showDots}
        autoPlaySpeed={3000}
        containerClass="card-slider-container px-2"
        itemClass="slider-item-padding-40-px"
      >
       {pricingData.map((card) => (
        <div key={card.id} className="pricing-card mx-0 mb-4">
          {/* Background Circles */}
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
          <div className="circle-4"></div>
          <div className="circle-5"></div>

          {/* Top Section with Waves */}
          <div className="card-top">
            {/* Multiple Waves */}
            <svg
              className="top-wave top-wave-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 600"
            >
              <path
                fill="#FE8200"
                fillOpacity="0.8"
                d="M0,200L48,194.7C96,189,192,179,288,173.3C384,168,480,168,576,162.7C672,157,768,147,864,157.3C960,168,1056,200,1152,205.3C1248,211,1344,189,1392,178.7L1440,168L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
              ></path>
            </svg>


            <svg
              className="top-wave top-wave-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 500"
            >
              <path
                fill="#FF9C40"
                fillOpacity="0.7"
                d="M0,320L48,307.3C96,294,192,264,288,245.3C384,226,480,218,576,213.3C672,208,768,221,864,245.3C960,269,1056,309,1152,311C1248,313,1344,269,1392,250.7L1440,232L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
              ></path>
            </svg>

            <svg
              className="top-wave top-wave-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 400" // Increased height of the viewBox
            >
              <path
                fill="#FFBB77"
                fillOpacity="0.3"
                d="M0,256L48,234.7C96,213,192,171,288,160C384,149,480,171,576,192C672,213,768,235,864,245.3C960,256,1056,235,1152,213.3C1248,192,1344,171,1392,160L1440,149.3L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
              ></path>
            </svg>

            <h4 className="card-title">{card.title}</h4>
            {/* <p className="card-tag">dffhasdgshdfdbhsdbhffhs</p> */}
            <div className="price-circle">
              <p className='price'>{card.price}</p>
              <sub>/mo</sub>
            </div>
          </div>

          {/* Content Section */}
          <div className="card-content">
            
            <ul className="features text-start mx-5">
              {card.features.map((feature, index) => (
                <li key={index}>
                  <FaCheckCircle className="checkcircle-card" />
                  {feature}
                </li>
              ))}
            </ul>
            <button
                className="start-button mb-4"
                onClick={() => handleNavigate(card.link)}
              >
                ORDER NOW <IoIosArrowForward style={{ fontSize: "15px" }} />
              </button>
          </div>

          {/* Bottom Section */}
          <div className="card-bottom">
            <svg
              className="bottom-wave"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 400"
            >
              <path
                fill="#FE8200"
                fillOpacity="0.1"
                d="M0,64L48,96C96,128,192,192,288,224C384,256,480,256,576,234.7C672,213,768,171,864,149.3C960,128,1056,128,1152,138.7C1248,149,1344,171,1392,181.3L1440,192L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
              ></path>
            </svg>
          </div>
        </div>
      ))}
      </Carousel>
    </div>
  );
};

export default PricingCard;

