import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../MainCSS/postsection.css'; // Import the CSS file here
import post1 from '../Images/postimg/1.png';
import post2 from '../Images/postimg/2.png';
import post3 from '../Images/postimg/3.png';
import post4 from '../Images/postimg/4.png';

const BlogPage = () => {
  const blogData = [
    {
      image: post1,
      title: 'How to Reset WordPress Password Via Softaculous',
      description: 'Resetting your WordPress password via Softaculous is a quick and easy way to regain access...',
      readMoreLink: '#',
    },
    {
      image: post2,
      title: 'How To Fix Elementor Stuck On Loading Screen',
      description: 'Elementor simplifies the process of developing website pages in WordPress. However...',
      readMoreLink: '#',
    },
    {
      image: post3,
      title: 'Install WordPress using Softaculous - Cpanel',
      description: 'Go to your control panel (e.g. cPanel, Directadmin, etc) login page and enter the correct login...',
      readMoreLink: '#',
    },
    {
      image: post4,
      title: 'Install Android APP for wordpress website',
      description: 'Installing an Android app for your WordPress website is a great way to enhance user experience...',
      readMoreLink: '#',
    },
  ];

  return (
    <Container fluid className="blog-container mt-2 px-5">
      <Row>
        <Col className='mb-2'>
        <h1 className="text-center mt-3 mb-2 tab-heading">Explore Experts Posts & Articles</h1>
          <p className='blog-description' style={{padding:'0 15%'}}>Stay updated with tips, tutorials, and solutions to enhance your web hosting and WordPress experience.</p>
        </Col>
      </Row>
      <Row xs={1} sm={2} md={4} >
        {blogData.map((blog, index) => (
          <Col key={index} className='mb-4'>
            <Card className="blog-card h-100">
              <Card.Img variant="top" src={blog.image} alt={blog.title} />
              <Card.Body>
                <Card.Title className='text-start'>{blog.title}</Card.Title>
                <Card.Text className='text-start'>{blog.description}</Card.Text>
                <Button className='blog-btn' href={blog.readMoreLink}>
                  Read More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogPage;
