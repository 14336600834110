// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle, FaArrowRight } from "react-icons/fa";

// const AboutMenu = () => {
//     const [selectedSection, setSelectedSection] = useState("About Us");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">About Our Company</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">At the heart of our success is a dynamic team and inspiring leaders who make it all possible.</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "About Us" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("About Us")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             About Us
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Founder & CEO" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Founder & CEO")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Founder & CEO
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Our Team" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Our Team")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Our Team
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Technologies We Use" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Technologies We Use")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Technologies We Use
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Why With Us" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Why With Us")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Why With Us
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "About Us" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">About Us</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{ fontWeight: '750' }}>Founded in 2019</h5>
//                             <p className="text">
//                                 We are a reputable provider of Managed Web Hosting, Cloud cPanel Hosting, Dedicated Servers, as well as Web Design and Digital Marketing services.</p>
//                         </div>
//                     )}

//                     {selectedSection === "Founder & CEO" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Founder & CEO</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{ fontWeight: '750' }}>Meet Our Founder, CEO & Managing Director</h5>
//                             <p>Nitin Bagul, founder and CEO of BigHost India, leads with a vision for innovation in cloud hosting and digital solutions, ensuring the company’s growth and success.</p>
//                             <p className="text" style={{ fontSize: '25px', fontWeight: 'bold', color: '#fe8200' }}>Mr. Nitin Bagul <FaArrowRight style={{ fontSize: '19px' }} /> </p>
//                         </div>
//                     )}

//                     {selectedSection === "Our Team" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Our Team</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{ fontWeight: '750' }}>The Team behind the Screen!</h5>
//                             <p>At BigHost India Private Limited, we pride ourselves on the expertise and dedication of our talented team. From the strategic leadership of <span style={{color:'#fe8200',fontWeight:'bold'}}>Nitin Bagul</span> (CEO & Founder) and <span style={{color:'#fe8200',fontWeight:'bold'}}>Rani Bagul</span> (CEO & Director)</p>
//                             {/* <p className="text" style={{ fontSize: '25px', fontWeight: 'bold', color: '#fe8200' }}>Mr. Nitin Bagul <FaArrowRight style={{ fontSize: '19px' }} /> </p> */}
//                         </div>
//                     )}

//                     {selectedSection === "Technologies We Use" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Technologies We Use</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{ fontWeight: '750' }}>The Team behind the Screen!</h5> */}
//                             <p className="text">
//                                 BigHost India offers fast, secure, and reliable fully managed cloud hosting with top providers, ensuring high performance and seamless operations for businesses globally.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Fast Hosting Solutions</p>
//                                     <p><FaCheckCircle className="checkcircle" />Secure Infrastructure</p>
//                                     <p><FaCheckCircle className="checkcircle" />Fully Managed Cloud Hosting</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Scalable Solutions</p>
//                                     <p><FaCheckCircle className="checkcircle" />Dedicated Support</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Why With Us" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Why With Us</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h5 style={{ fontWeight: '750' }}>Fully Managed Cloud Hosting</h5>
//                             <p className="text">
//                                 BigHost is a managed hosting company. The reason you should choose BigHost for your business is the fact that we provide a fully managed hosting environment optimized for running your websites.</p>

//                         </div>
//                     )}


//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AboutMenu;


import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Button, Form } from 'react-bootstrap';
import '../MainCSS/domain.css';
import { BiPhoneCall } from "react-icons/bi";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";

const AboutMenu = () => {
    const [selectedSection, setSelectedSection] = useState("About Us");

    return (
        <Container className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar (col-md-4) */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">About Our Company</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">
                                At the heart of our success is a dynamic team and inspiring leaders who make it all possible.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "About Us"}
                                    onMouseEnter={() => setSelectedSection("About Us")}
                                >
                                    About Us
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Founder & CEO"}
                                    onMouseEnter={() => setSelectedSection("Founder & CEO")}
                                >
                                    Founder & CEO
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Our Team"}
                                    onMouseEnter={() => setSelectedSection("Our Team")}
                                >
                                    Our Team
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Technologies We Use"}
                                    onMouseEnter={() => setSelectedSection("Technologies We Use")}
                                >
                                    Technologies We Use
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Why With Us"}
                                    onMouseEnter={() => setSelectedSection("Why With Us")}
                                >
                                    Why With Us
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content (col-md-8) */}
                <Col md={8} className="bg-white">
                    {selectedSection === "About Us" && (
                        <Container>
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">About Us</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h5 style={{ fontWeight: '750' }}>Founded in 2019</h5>
                            <p className="text">
                                We are a reputable provider of Managed Web Hosting, Cloud cPanel Hosting, Dedicated Servers, as well as Web Design and Digital Marketing services.
                            </p>
                        </Container>
                    )}

                    {selectedSection === "Founder & CEO" && (
                        <Container className="pb-5">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Founder & CEO</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h5 style={{ fontWeight: '750' }}>Meet Our Founder, CEO & Managing Director</h5>
                            <p>
                                Nitin Bagul, founder and CEO of BigHost India, leads with a vision for innovation in cloud hosting and digital solutions, ensuring the company’s growth and success.
                            </p>
                            <p className="text" style={{ fontSize: '25px', fontWeight: 'bold', color: '#fe8200' }}>
                                Mr. Nitin Bagul <FaArrowRight style={{ fontSize: '19px' }} />
                            </p>
                        </Container>
                    )}

                    {selectedSection === "Our Team" && (
                        <Container className="pb-5">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Our Team</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h5 style={{ fontWeight: '750' }}>The Team behind the Screen!</h5>
                            <p>
                                At BigHost India Private Limited, we pride ourselves on the expertise and dedication of our talented team. From the strategic leadership of
                                <span style={{ color: '#fe8200', fontWeight: 'bold' }}> Nitin Bagul</span> (CEO & Founder) and
                                <span style={{ color: '#fe8200', fontWeight: 'bold' }}> Rani Bagul</span> (CEO & Director)
                            </p>
                        </Container>
                    )}

                    {selectedSection === "Technologies We Use" && (
                        <Container className="pb-5">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Technologies We Use</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                BigHost India offers fast, secure, and reliable fully managed cloud hosting with top providers, ensuring high performance and seamless operations for businesses globally.
                            </p>
                            <Row className="d-flex">
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" /> Fast Hosting Solutions</p>
                                    <p><FaCheckCircle className="checkcircle" /> Secure Infrastructure</p>
                                    <p><FaCheckCircle className="checkcircle" /> Fully Managed Cloud Hosting</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" /> Scalable Solutions</p>
                                    <p><FaCheckCircle className="checkcircle" /> Dedicated Support</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Why With Us" && (
                        <Container className="pb-5">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Why With Us</p>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h5 style={{ fontWeight: '750' }}>Fully Managed Cloud Hosting</h5>
                            <p className="text">
                                BigHost is a managed hosting company. The reason you should choose BigHost for your business is the fact that we provide a fully managed hosting environment optimized for running your websites.
                            </p>
                        </Container>
                    )}

                    {/* Footer for col-md-8 */}
                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutMenu;
