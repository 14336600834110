// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// // import { FaCheckCircle } from "react-icons/fa";

// const Solutions = () => {
//     const [selectedSection, setSelectedSection] = useState("Customer Care");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">Support</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">We’re here to provide dedicated support, ensuring your journey with us is seamless and successful.</p>
//                     <ul className="list-group mb-5">
//                         <li
//                             className={`list-group-item ${selectedSection === "Customer Care" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Customer Care")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Customer Care
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Open Support Ticket" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Open Support Ticket")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Open Support Ticket
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Server Status" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Server Status")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Server Status
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Knowledgebase" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Knowledgebase")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Knowledgebase
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "Customer Care" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Customer Care</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <h4 style={{ fontWeight: '750' }}>We're here to <span style={{ color: '#fe8200' }}>help 24/7</span></h4>
//                             <p className="text mb-2">Use our live chat with our sales & support team for quick answers on product features, pricing and more.</p>
//                         </div>
//                     )}

//                     {selectedSection === "Open Support Ticket" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Open Support Ticket</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">An Open Support Ticket system enables customers to easily report issues or request assistance. It streamlines the process by assigning tickets to the appropriate team member and providing updates, ensuring quick resolution of property-related concerns and enhancing customer satisfaction.</p>
//                             <a href="https://billing.bighostindia.in/login" style={{ color: '#fe8200', fontWeight: 'bold', fontSize: '18px' }}>Open Ticket</a>
//                         </div>
//                     )}

//                     {selectedSection === "Server Status" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Server Status</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">Server Status provides real-time updates on the health and performance of the system. It helps users monitor server uptime, track any issues, and ensure smooth operation of property management services. This feature ensures transparency and quick identification of potential problems, allowing for timely resolutions and minimal service disruption.</p>
//                         </div>
//                     )}

//                     {selectedSection === "Knowledgebase" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2s">Knowledgebase</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text mb-2">The Knowledgebase provided by BigHost offers a collection of informative articles to help users troubleshoot, learn, and make the most of property management features. It covers a wide range of topics, offering step-by-step guides, FAQs, and best practices to assist with common issues, ensuring users can find solutions quickly and independently.</p>
//                         </div>
//                     )}

//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Solutions;


import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle } from "react-icons/fa";

const Solutions = () => {
    const [selectedSection, setSelectedSection] = useState("Customer Care");

    return (
        <Container className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar (col-md-4) */}
                <Col md={4} className="bg-light">
                    <Row>
                        <Col>
                            <p className="mainheading mb-2">Support</p>
                            <p className="headingborder"></p>
                            <p className="text-muted mb-none">We’re here to provide dedicated support, ensuring your journey with us is seamless and successful.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup className="mb-5">
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Customer Care"}
                                    onMouseEnter={() => setSelectedSection("Customer Care")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Customer Care
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Open Support Ticket"}
                                    onMouseEnter={() => setSelectedSection("Open Support Ticket")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Open Support Ticket
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Server Status"}
                                    onMouseEnter={() => setSelectedSection("Server Status")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Server Status
                                </ListGroup.Item>
                                <ListGroup.Item
                                    action
                                    active={selectedSection === "Knowledgebase"}
                                    onMouseEnter={() => setSelectedSection("Knowledgebase")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Knowledgebase
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content (col-md-8) */}
                <Col md={8} className="bg-white">
                    {selectedSection === "Customer Care" && (
                        <div className="w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Customer Care</p>
                                </Col>
                                <Col className="text-end">
                                    <Button className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <h4 style={{ fontWeight: '750' }}>We're here to <span style={{ color: '#fe8200' }}>help 24/7</span></h4>
                            <p className="text mb-2">Use our live chat with our sales & support team for quick answers on product features, pricing and more.</p>
                        </div>
                    )}

                    {selectedSection === "Open Support Ticket" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Open Support Ticket</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="link" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">An Open Support Ticket system enables customers to easily report issues or request assistance. It streamlines the process by assigning tickets to the appropriate team member and providing updates, ensuring quick resolution of property-related concerns and enhancing customer satisfaction.</p>
                            <a href="https://billing.bighostindia.in/login" style={{ color: '#fe8200', fontWeight: 'bold', fontSize: '18px' }}>Open Ticket</a>
                        </div>
                    )}

                    {selectedSection === "Server Status" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Server Status</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="link" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">Server Status provides real-time updates on the health and performance of the system. It helps users monitor server uptime, track any issues, and ensure smooth operation of property management services. This feature ensures transparency and quick identification of potential problems, allowing for timely resolutions and minimal service disruption.</p>
                        </div>
                    )}

                    {selectedSection === "Knowledgebase" && (
                        <div className="pb-5 w-100">
                            <Row className="d-flex justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2s">Knowledgebase</p>
                                </Col>
                                <Col className="text-end">
                                    <Button variant="link" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text mb-2">The Knowledgebase provided by BigHost offers a collection of informative articles to help users troubleshoot, learn, and make the most of property management features. It covers a wide range of topics, offering step-by-step guides, FAQs, and best practices to assist with common issues, ensuring users can find solutions quickly and independently.</p>
                        </div>
                    )}

                    {/* Footer for col-md-8 */}
                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Solutions;
