// import React, { useState } from "react";
// import { Row, Col, Card } from "react-bootstrap";
// import '../MainCSS/Review.css'
// import Trustpilot from '../Images/trustpilot.png';
// import HostAdvice from '../Images/hostadvice.png';
// import Google from '../Images/google.png';
// import TrustIndex from '../Images/Trustindex.png';

// const ReviewsSection = () => {
//     const reviews = [
//         {
//             logo: Google,
//             review:
//                 "I had a positive experience with BigHost India PVT Ltd. The team were skilled and provided clear, effective solutions. Their communication was excellent, and I felt well-informed throughout the process and would recommend them to others.",
//             name: "Bhushan Dange",
//             date: "Aug 26, 2024",
//             stars: 5,
//         },
//         {
//             logo: HostAdvice,
//             review:
//                 "BigHost India Hosting is Awesome!! Their servers are completely stable and secured and if you have any issues, customer service is right there, waiting and ready at any moment. The rates are affordable and you definitely get what you're paying for. Highly recommended for hosting.",
//             name: "Arjun Industries",
//             date: "12 Apr, 2022",
//             stars: 5,
//         },
//         {
//             logo: TrustIndex,
//             review:
//                 "Great customer support, reliable servers. This is an excellent all round web hosting site, it's affordable, fast and reliable. Customer Support Team reply back very quickly and they are very helpful! I definitely recommend BigHost India to you anyone who wants a pleasant and smooth experience.",
//             name: "Grace Global",
//             date: "Dec 25, 2022",
//             stars: 5,
//         },
//         {
//             logo: Trustpilot,
//             review:
//                 "I’ve been using BigHost India for a few months, and it’s been a great experience overall. The hosting is affordable, with good uptime and a user-friendly control panel. Setting up my website was simple, and their free SSL and one-click WordPress installation were a big help.Customer support is responsive, but speeds for international users could be better, and there’s a lack of advanced features for developers. If you’re looking for reliable, budget-friendly hosting in India, BigHost India is a solid choice!",
//             name: "Ashwini Chavan",
//             date: "May 13, 2024",
//             stars: 5,
//         },
//     ];

//     const renderStars = (count) => {
//         const stars = [];
//         for (let i = 0; i < count; i++) {
//             stars.push(
//                 <span key={i} className="star">
//                     ★
//                 </span>
//             );
//         }
//         return stars;
//     };

//     // Function to toggle "Read More" and "Read Less"
//     const [expandedReview, setExpandedReview] = useState({});

//     const handleReadMoreToggle = (index) => {
//         setExpandedReview((prevState) => ({
//             ...prevState,
//             [index]: !prevState[index],
//         }));
//     };

//     return (
//         <div className="reviews-section px-4">

//             {/* Header Section */}
//             <Row className="reviews-header">
//                 <Col>
//                     <h2>BigHost Trusted by Thousands Worldwide</h2>
//                     <p>
//                         Hosting <span style={{ color: "#FE8200", fontWeight: "bold" }}>World-class solutions</span> for clients across{" "}
//                         <span style={{ color: "#FE8200", fontWeight: "bold" }}>industries</span>
//                     </p>
//                 </Col>
//             </Row>

//             {/* Reviews Section */}
//             <Row className="g-4">
//                 {reviews.map((review, index) => (
//                     <Col key={index} lg={3} md={6} sm={12}>
//                         <Card className="review-card">
//                             <Card.Body>
//                                 <div className="d-flex align-items-center gap-2 mb-3">
//                                     <img
//                                         src={review.logo}
//                                         alt={`${review.platform} Logo`}
//                                         className="review-logo"
//                                     />
//                                     <span className="review-platform">{review.platform}</span>
//                                 </div>
//                                 <Card.Text className="review-text">
//                                     {expandedReview[index]
//                                         ? review.review
//                                         : `${review.review.substring(0, 185)}...`}
//                                     <button
//                                         className="read-more-btn"
//                                         onClick={() => handleReadMoreToggle(index)}
//                                     >
//                                         {expandedReview[index] ? "Show Less" : "Show More"}
//                                     </button>
//                                 </Card.Text>

//                                 <div className="d-flex align-items-center gap-2 mt-3 star-rating justify-content-between">
//                                     <div className="mt-1">
//                                         <strong className="review-name text-start">{review.name}</strong>
//                                         <div className="review-date text-start">{review.date}</div>
//                                     </div>
//                                     <span className="text-end">{renderStars(review.stars)}</span>

//                                 </div>

//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 ))}
//             </Row>

//             {/* Footer Section */}
//             <Row className="reviews-footer">
//                 <Col>
//                     <p>
//                         Our customers say <span className="rating">Excellent</span> <span className="star" > ★★★★★</span>{" "}
//                         {" "}
//                         <span className="rating">4.6 out of 5</span> based on{" "}
//                         <span className="reviews-count">858 user reviews</span>
//                     </p>
//                 </Col>
//             </Row>

//         </div>
//     );
// };

// export default ReviewsSection;



import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../MainCSS/Review.css';
import Trustpilot from '../Images/trustpilot.png';
import HostAdvice from '../Images/hostadvice.png';
import Google from '../Images/google.png';
import TrustIndex from '../Images/Trustindex.png';

const ReviewsSection = () => {
    const reviews = [
        {
            logo: Google,
            review:
                "I had a positive experience with BigHost India PVT Ltd. The team were skilled and provided clear, effective solutions. Their communication was excellent, and I felt well-informed throughout the process and would recommend them to others.",
            name: "Bhushan Dange",
            date: "Aug 26, 2024",
            stars: 5,
        },
        {
            logo: HostAdvice,
            review:
                "BigHost India Hosting is Awesome!! Their servers are completely stable and secured and if you have any issues, customer service is right there, waiting and ready at any moment. The rates are affordable and you definitely get what you're paying for. Highly recommended for hosting.",
            name: "Arjun Industries",
            date: "12 Apr, 2022",
            stars: 5,
        },
        {
            logo: TrustIndex,
            review:
                "Great customer support, reliable servers. This is an excellent all round web hosting site, it's affordable, fast and reliable. Customer Support Team reply back very quickly and they are very helpful! I definitely recommend BigHost India to anyone who wants a pleasant and smooth experience.",
            name: "Grace Global",
            date: "Dec 25, 2022",
            stars: 5,
        },
        {
            logo: Trustpilot,
            review:
                "I’ve been using BigHost India for a few months, and it’s been a great experience overall. The hosting is affordable, with good uptime and a user-friendly control panel. Setting up my website was simple, and their free SSL and one-click WordPress installation were a big help.Customer support is responsive, but speeds for international users could be better, and there’s a lack of advanced features for developers. If you’re looking for reliable, budget-friendly hosting in India, BigHost India is a solid choice!",
            name: "Ashwini Chavan",
            date: "May 13, 2024",
            stars: 5,
        },
    ];

    const renderStars = (count) => {
        return Array.from({ length: count }, (_, i) => (
            <span key={i} className="star">
                ★
            </span>
        ));
    };

    const [carouselSettings, setCarouselSettings] = useState({
        infinite: false,
        autoPlay: false,
        arrows: false,
        showDots: false,
    });

    // Detect screen size and adjust carousel settings
    useEffect(() => {
        const updateCarouselSettings = () => {
            if (window.innerWidth < 768) {
                setCarouselSettings({
                    infinite: true,
                    autoPlay: true,
                    arrows: false,
                    showDots: true,
                });
            } else {
                setCarouselSettings({
                    infinite: false,
                    autoPlay: false,
                    arrows: false,
                    showDots: false,
                });
            }
        };

        updateCarouselSettings();
        window.addEventListener("resize", updateCarouselSettings);
        return () => window.removeEventListener("resize", updateCarouselSettings);
    }, []);

    const [expandedReview, setExpandedReview] = useState({});

    const handleReadMoreToggle = (index) => {
        setExpandedReview((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 1200 }, items: 4 },
        desktop: { breakpoint: { max: 1200, min: 992 }, items: 3 },
        tablet: { breakpoint: { max: 992, min: 768 }, items: 2 },
        mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
    };

    return (
        <div className="reviews-section px-4 pb-4">
            {/* Header Section */}
            <Row className="reviews-header">
                <Col>
                    <h2 className="mb-2">BigHost Trusted by Thousands Worldwide</h2>
                    <p style={{padding:'0 15%'}}>
                        Hosting World-class solutions for clients across industries
                    </p>
                </Col>
            </Row>

            {/* Reviews Carousel */}
            <div className="carousel-section">
                <Carousel
                    responsive={responsive}
                    infinite={carouselSettings.infinite}
                    autoPlay={carouselSettings.autoPlay}
                    arrows={carouselSettings.arrows}
                    showDots={carouselSettings.showDots}
                    autoPlaySpeed={3000}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px"
                >
                    {reviews.map((review, index) => (
                        <div key={index} className="p-2">
                            <Card className="review-card">
                                <Card.Body>
                                    <div className="d-flex align-items-center gap-2 mb-3">
                                        <img
                                            src={review.logo}
                                            alt={`${review.name} Logo`}
                                            className="review-logo"
                                        />
                                    </div>
                                    <Card.Text className="review-text">
                                        {expandedReview[index]
                                            ? review.review
                                            : `${review.review.substring(0, 183)}...`}
                                        <Button
                                            variant="link"
                                            className="read-more p-0"
                                            onClick={() => handleReadMoreToggle(index)}
                                        >
                                            {expandedReview[index] ? "Show Less" : "Show More"}
                                        </Button>
                                    </Card.Text>
                                    <div className="d-flex align-items-center gap-2 mt-3 star-rating justify-content-between">
                                        <div className="mt-1">
                                            <strong className="review-name text-start">{review.name}</strong>
                                            <div className="review-date text-start">{review.date}</div>
                                        </div>
                                        <span className="text-end">{renderStars(review.stars)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Footer Section */}
            <Row className="reviews-footer mt-4">
                <Col>
                    <p>
                        Our customers say <span className="rating">Excellent</span>{' '}
                        <span className="star">★★★★★</span>{' '}
                        <span className="rating">4.6 out of 5</span> based on{' '}
                        <span className="reviews-count">858 user reviews</span>
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default ReviewsSection;
