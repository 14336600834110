// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import "../MainCSS/technology.css";
// import cloud1 from "../Images/tech/cpanel-whm-logo.png";
// import cloud2 from "../Images/tech/litespeed.png";
// import cloud3 from "../Images/tech/cloud-linux.png";
// import cloud4 from "../Images/tech/imunify.png";
// import cloud5 from "../Images/tech/cloudflare.png";
// import cloud6 from "../Images/tech/jetbackup-bighost.png";

// import html5Logo from "../Images/tech/html-logo.png";
// import css3Logo from "../Images/tech/css-logo.png";
// import javascriptLogo from "../Images/tech/js-logo.png";
// import reactLogo from "../Images/tech/react-logo.png";
// import nextjslogo from "../Images/tech/nodejs-logo.png";
// import mongodblogo from "../Images/tech/mongodb-logo.png";
// import expressjslogo from "../Images/tech/expressjs-logo.png";
// import mysqllogo from "../Images/tech/mysql-logo.png";
// import angularlogo from "../Images/tech/angular-logo.png";
// import phplogo from "../Images/tech/php-logo.png";
// import pythonlogo from "../Images/tech/python-logo.png";

// const technologies = {
//   cloud: [
//     { name: "cpanel", img: cloud1 },
//     { name: "litespeed", img: cloud2 },
//     { name: "cloud-linux", img: cloud3 },
//     { name: "imunify", img: cloud4 },
//     { name: "cloudflare", img: cloud5 },
//     { name: "jetbackup", img: cloud6 },
//   ],
//   design: [
//     { name: "HTML5", img: html5Logo },
//     { name: "CSS3", img: css3Logo },
//     { name: "JavaScript", img: javascriptLogo },
//     { name: "React", img: reactLogo },
//     { name: "React", img: nextjslogo },
//     { name: "React", img: mongodblogo },
//     { name: "React", img: expressjslogo },
//     { name: "React", img: mysqllogo },
//     { name: "React", img: angularlogo },
//     { name: "React", img: phplogo },
//     { name: "React", img: pythonlogo },
//   ],
// };
// ;

// const TechSection = () => {
//   const duplicateArray = (arr) => [...arr, ...arr]; // Duplicate array for infinite scroll

//   return (
//     <Container className="tech-section">
//       <Row>
//         <Col>
//           <h1>Technologies We Use</h1>
//           <p>
//             Our talented IT specialists are experts in a wide range of technologies,
//             spanning cloud and development solutions to deliver the best results.
//           </p>
//         </Col>
//       </Row>

//       <Row className="tech-category">
//         <Col>
//           <h2>Cloud Technologies</h2>
//           <Row className="tech-scroller">
//             {duplicateArray(technologies.cloud).map((tech, index) => (
//               <Col key={index} className="tech-card">
//                 <img src={tech.img} alt={tech.name} />
//                 <p>{tech.name}</p>
//               </Col>
//             ))}
//           </Row>
//         </Col>
//       </Row>

//       <Row className="tech-category">
//         <Col>
//           <h2>Design & Development Technologies</h2>
//           <Row className="tech-scroller">
//             {duplicateArray(technologies.design).map((tech, index) => (
//               <Col key={index} className="tech-card">
//                 <img src={tech.img} alt={tech.name} />
//                 <p>{tech.name}</p>
//               </Col>
//             ))}
//           </Row>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TechSection;



import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from "react-bootstrap";
import "../MainCSS/technology.css";

// Cloud Technology Images
import cloud1 from "../Images/tech/cpanel-whm-logo.png";
import cloud2 from "../Images/tech/litespeed.png";
import cloud3 from "../Images/tech/cloud-linux.png";
import cloud4 from "../Images/tech/imunify.png";
import cloud5 from "../Images/tech/cloudflare.png";
import cloud6 from "../Images/tech/jetbackup-bighost.png";

// Development Technology Images
import html5Logo from "../Images/tech/html-logo.png";
import css3Logo from "../Images/tech/css-logo.png";
import javascriptLogo from "../Images/tech/js-logo.png";
import reactLogo from "../Images/tech/react-logo.png";
import nextjsLogo from "../Images/tech/nodejs-logo.png";
import mongodbLogo from "../Images/tech/mongodb-logo.png";
import expressjsLogo from "../Images/tech/expressjs-logo.png";
import mysqlLogo from "../Images/tech/mysql-logo.png";
import angularLogo from "../Images/tech/angular-logo.png";
import phpLogo from "../Images/tech/php-logo.png";
import pythonLogo from "../Images/tech/python-logo.png";

const SlickSlider = () => {
    const cloudSlides = [
        { id: 1, img: cloud1 },
        { id: 2, img: cloud2 },
        { id: 3, img: cloud3 },
        { id: 4, img: cloud4 },
        { id: 5, img: cloud5 },
        { id: 6, img: cloud6 },
    ];

    const devSlides = [
        { id: 1, img: html5Logo, name: "HTML5" },
        { id: 2, img: css3Logo, name: "CSS3" },
        { id: 3, img: javascriptLogo, name: "JavaScript" },
        { id: 4, img: reactLogo, name: "React.js" },
        { id: 5, img: nextjsLogo, name: "Next.js" },
        { id: 6, img: mongodbLogo, name: "MongoDB" },
        { id: 7, img: expressjsLogo, name: "Express.js" },
        { id: 8, img: mysqlLogo, name: "MySQL" },
        { id: 9, img: angularLogo, name: "Angular" },
        { id: 10, img: phpLogo, name: "PHP" },
        { id: 11, img: pythonLogo, name: "Python" },
    ];

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     speed: 3000,
    //     cssEase: "linear",
    //     arrows: false,
    //     pauseOnHover: false,
    //     variableWidth: true,
    // };

    const settings = {
        dots: false,
        infinite: true, // Enable infinite scrolling
        slidesToShow: 4, // This controls the visible slides on larger screens
        slidesToScroll: 1, // Ensure smooth scroll without skipping slides
        autoplay: true, // Autoplay ensures continuous movement
        autoplaySpeed: 0, // Make it scroll continuously
        speed: 7000, // Control the speed of the scroll
        cssEase: "linear", // Linear easing for smooth movement
        arrows: false, // No arrows for a cleaner UI
        pauseOnHover: false, // Prevent pausing on hover for uninterrupted flow
        variableWidth: true, // Allow slides to adjust width dynamically
        responsive: [
            {
                breakpoint: 768, // For smaller screens
                settings: {
                    slidesToShow: 1, // Show one slide at a time but maintain flow
                    slidesToScroll: 1, // Keep scrolling smooth
                    infinite: true,
                    variableWidth: true, // Maintain dynamic width
                    autoplay: true, // Continue autoplay
                    autoplaySpeed: 0, // Continuous scroll
                    speed: 7000,
                    cssEase: "linear",
                },
            },
        ],
    };
    
    

    return (
        <Container fluid className="slick-slider-container pb-4">
            <Row className="mt-3 mb-2">
                <Col >
                    <p className="top-container">Technologies We Work With</p>
                    <p className="tech-para" style={{padding:'0 15%'}}>We specialize in cloud services and design and development, utilizing advanced technologies to build scalable solutions, modern web applications, and intuitive digital experiences that drive business success.</p>
                </Col>
            </Row>
            {/* Cloud Technologies Row */}
            <Row>
                <Col>
                    <h2>Cloud Technologies</h2>
                    <Slider {...settings}>
                        {cloudSlides.map((slide) => (
                            <Col
                                key={slide.id}
                                className="slick-slide-cloud slide-bg-cloud d-flex justify-content-center align-items-center"
                            >
                                <img src={slide.img} alt="Cloud Technology" className="slide-image" />
                            </Col>
                        ))}
                    </Slider>
                </Col>
            </Row>


            {/* Development Technologies Row */}
            <Row>
                <Col>
                    <h2>Development Technologies</h2>
                    <Slider {...settings}>
                        {devSlides.map((slide) => (
                            <Col key={slide.id} className="slick-slide d-flex">
                                <Row className="slide-bg">
                                    {/* Image in one column */}
                                    <Col xs={5} className="d-flex justify-content-end imagerow">
                                        <img src={slide.img} alt={slide.name} className="slide-image-dev" />
                                    </Col>
                                    {/* Name in one column */}
                                    <Col xs={7} className="d-flex align-items-center justify-content-start">
                                        <p className="slide-name">{slide.name}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Slider>
                </Col>
            </Row>

        </Container>
    );
};

export default SlickSlider;
