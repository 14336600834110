// import React, { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import "../MainCSS/development.css";
// import webDevImg from "../Images/DummyImg/Web.png";
// import webAppImg from "../Images/DummyImg/home2-Web-Design.png";
// import ecommerceImg from "../Images/DummyImg/eCommerce.png";
// import pwaImg from "../Images/DummyImg/PWA.png";
// import industriesImg from "../Images/DummyImg/industries.png";
// import solutionsImg from "../Images/DummyImg/solutions.png";

// const TabView = () => {
//     const [activeTab, setActiveTab] = useState("webdevelopment");

//     const tabs = [
//         { key: "webdevelopment", label: "Web Development", image: webDevImg },
//         { key: "webapplication", label: "Web Application Development", image: webAppImg },
//         { key: "ecommerce", label: "eCommerce Development", image: ecommerceImg },
//         { key: "pwa", label: "PWA Development", image: pwaImg },
//         { key: "industries", label: "Industries", image: industriesImg },
//         { key: "solutions", label: "Solutions", image: solutionsImg },
//     ];

//     const renderContent = () => {
//         switch (activeTab) {
//             case "webdevelopment":
//                 return (
//                     "We provide tailored web development services using modern frameworks and " +
//                     "technologies such as React, Angular, and Vue.js to create fast, responsive, " +
//                     "and visually appealing websites that enhance user engagement and achieve " +
//                     "business objectives."
//                 );
//             case "webapplication":
//                 return ("BigHost specializes in delivering reliable and efficient web application development services tailored to meet diverse business needs. With a focus on quality and innovation, we have successfully created scalable and user-friendly web applications for clients across industries such as eCommerce, education, and retail. Our expertise lies in leveraging modern technologies to provide secure and high-performance solutions that align with your business objectives.");
//             case "ecommerce":
//                 return (
//                     "We create powerful eCommerce platforms with features like inventory " +
//                     "management, secure payment gateways, and an optimized user experience " +
//                     "to help your online store stand out and succeed in a competitive market."
//                 );
//             case "pwa":
//                 return ("Provide an edge to your business's online presence with customized PWAs that hook your users with lightning speed and performance. We are a first-class progressive web app development company, delivering highly responsive, scalable, and outstanding user experiences through our next-gen progressive web apps. Our cross-platform, dynamic PWAs combine the power of mobile and web apps to give wings to your business outreach.");
//             case "industries":
//                 return (
//                     "We serve a variety of industries, including healthcare, education, real estate, " +
//                     "finance, and retail, offering custom development solutions that address " +
//                     "specific industry challenges and optimize operations."
//                 );
//             case "solutions":
//                 return (
//                     "From CRM systems to ERP platforms, we deliver complete technology solutions " +
//                     "tailored to your business. Our solutions are designed to streamline " +
//                     "processes, improve collaboration, and drive growth."
//                 );
//             default:
//                 return "Select a tab to see the content.";
//         }
//     };

//     const getActiveImage = () => {
//         const activeTabData = tabs.find((tab) => tab.key === activeTab);
//         return activeTabData ? activeTabData.image : null;
//     };

//     return (
//         <Container fluid className="tabcontainer">
//             {/* Main Heading and Paragraph */}
//             <Row>
//                 <Col>
//                     <h1 className="text-center mt-3 tab-heading">Our Professional Development Services</h1>
//                     <p className="text-center">
//                         Explore a wide range of development services tailored to your needs.
//                         From responsive websites to scalable applications, we help businesses
//                         innovate and grow with cutting-edge solutions.
//                     </p>
//                 </Col>
//             </Row>

//             {/* Tab Header */}
//             <Row>
//                 <Col>
//                     <div className="tab-header">
//                         {tabs.map((tab) => (
//                             <button
//                                 key={tab.key}
//                                 className={`tab-button ${activeTab === tab.key ? "active" : ""
//                                     }`}
//                                 onClick={() => setActiveTab(tab.key)}
//                             >
//                                 {tab.label}
//                             </button>
//                         ))}
//                     </div>
//                 </Col>
//             </Row>

//             {/* Tab Content */}
//             <Row className="tab-content">
//                 <Col md={8} className="px-5">
//                     <h2>{tabs.find((tab) => tab.key === activeTab)?.label}</h2>
//                     <p className="tab-para">{renderContent()}</p>
//                 </Col>
//                 <Col md={4}>
//                     <img
//                         src={getActiveImage()}
//                         alt={`${activeTab} illustration`}
//                         className="img-fluid"
//                     />
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default TabView;


// import React, { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { FaCheckCircle } from "react-icons/fa";
// import "../MainCSS/development.css";
// import webDevImg from "../Images/DummyImg/Web.png";
// import webAppImg from "../Images/DummyImg/home2-Web-Design.png";
// import ecommerceImg from "../Images/DummyImg/eCommerce.png";
// import pwaImg from "../Images/DummyImg/PWA.png";
// import industriesImg from "../Images/DummyImg/industries.png";
// import solutionsImg from "../Images/DummyImg/solutions.png";

// const TabView = () => {
//     const [activeTab, setActiveTab] = useState("webdevelopment");

//     const tabs = [
//         { key: "webdevelopment", label: "Web Development", image: webDevImg },
//         { key: "webapplication", label: "Web Application Development", image: webAppImg },
//         { key: "ecommerce", label: "eCommerce Development", image: ecommerceImg },
//         { key: "pwa", label: "PWA Development", image: pwaImg },
//         { key: "industries", label: "Industries", image: industriesImg },
//         { key: "solutions", label: "Solutions", image: solutionsImg },
//     ];

//     const tabContent = {
//         webdevelopment: {
//             text:
//                 "BigHost specializes in creating dynamic and high-performing websites that drive business success. Our web development services combine the latest technologies with creative design to deliver responsive, user-friendly, and scalable websites tailored to your business needs.",
//             list: ["Responsive Design", "SEO Optimized", "Fast Loading Speed", "End-to-End Development"],
//         },
//         webapplication: {
//             text:
//                 "BigHost delivers reliable web application development services tailored to eCommerce, education, and retail industries. We specialize in creating scalable, secure, and user-friendly solutions using modern technologies, ensuring high performance and alignment with your business goals.",
//             list: ["Customized Solution", "Innovative Technologies", "Secure Solutions"],
//         },
//         ecommerce: {
//             text:
//                 "Boost your eCommerce business with cutting-edge solutions designed for scalability, security, and seamless user experiences. Our tailored eCommerce platforms combine modern features and intuitive design to enhance engagement and drive growth.",
//             list: ["Custom Solutions", "Secure Payments", "Modern Features"],
//         },
//         pwa: {
//             text:
//                 "Enhance your business's online presence with our next-gen Progressive Web Apps (PWAs). We deliver highly responsive, scalable, and dynamic PWAs that combine the power of mobile and web apps for lightning-fast performance and outstanding user experiences.",
//             list: ["Lightning-Fast Performance", "Dynamic PWAs", "Outstanding UX"],
//         },
//         industries: {
//             text:
//                 "BigHost offers innovative solutions across various industries, providing tailored web applications to optimize processes, enhance user experiences, and drive growth. From education and eCommerce to healthcare and logistics, we deliver scalable and secure digital solutions designed to meet the unique needs of each sector.",
//             list: ["Education", "Real Estate", "Food & Hospitality","Logistics & Transportation"],
//         },
//         solutions: {
//             text:
//                 "BigHost provides innovative and efficient solutions to streamline business operations. Our advanced systems are designed to enhance productivity, improve customer engagement, and simplify complex workflows, offering scalable, secure, and user-friendly platforms to meet specific business needs.",
//             list: ["CarDeal Quotation System", "Transport Bilty Software", "Property Management System"],
//         },
//     };

//     const renderContent = () => {
//         const content = tabContent[activeTab];
//         return (
//             <div>
//                 <p>{content.text}</p>
//                 <ul className="inline-list">
//                     {content.list.map((item, index) => (
//                         <li key={index}>
//                             <FaCheckCircle className="check-icon" /> {item}
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         );
//     };

//     const getActiveImage = () => {
//         const activeTabData = tabs.find((tab) => tab.key === activeTab);
//         return activeTabData ? activeTabData.image : null;
//     };

//     return (
//         <Container fluid className="tabcontainer">
//             {/* Main Heading and Paragraph */}
//             <Row>
//                 <Col>
//                     <h1 className="text-center mt-3 tab-heading">Our Professional <span style={{color:'#fe8200'}}>Development</span> Services</h1>
//                     <p className="text-center">
//                         Explore a wide range of development services tailored to your needs.
//                         From responsive websites to scalable applications, we help businesses
//                         innovate and grow with cutting-edge solutions.
//                     </p>
//                 </Col>
//             </Row>

//             {/* Tab Header */}
//             <Row>
//                 <Col>
//                     <div className="tab-header">
//                         {tabs.map((tab) => (
//                             <button
//                                 key={tab.key}
//                                 className={`tab-button ${activeTab === tab.key ? "active" : ""}`}
//                                 onClick={() => setActiveTab(tab.key)}
//                             >
//                                 {tab.label}
//                             </button>
//                         ))}
//                     </div>
//                 </Col>
//             </Row>

//             {/* Tab Content */}
//             <Row className="tab-content">
//                 <Col md={8} className="px-5">
//                     <h2>{tabs.find((tab) => tab.key === activeTab)?.label}</h2>
//                     {renderContent()}
//                 </Col>
//                 <Col md={4}>
//                     <img
//                         src={getActiveImage()}
//                         alt={`${activeTab} illustration`}
//                         className="img-fluid"
//                     />
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default TabView;



import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import "../MainCSS/development.css";
import webDevImg from "../Images/DummyImg/Web.png";
import webAppImg from "../Images/DummyImg/home2-Web-Design.png";
import ecommerceImg from "../Images/DummyImg/eCommerce.png";
import pwaImg from "../Images/DummyImg/PWA.png";
import industriesImg from "../Images/DummyImg/industries.png";
import solutionsImg from "../Images/DummyImg/solutions.png";

const TabView = () => {
    const [activeTab, setActiveTab] = useState("webdevelopment");

    const tabs = [
        { key: "webdevelopment", label: "Web Development", image: webDevImg },
        { key: "webapplication", label: "Web Application Development", image: webAppImg },
        { key: "ecommerce", label: "eCommerce Development", image: ecommerceImg },
        { key: "pwa", label: "PWA Development", image: pwaImg },
        { key: "industries", label: "Industries", image: industriesImg },
        { key: "solutions", label: "Solutions", image: solutionsImg },
    ];

    const tabContent = {
        webdevelopment: {
            text:
                "BigHost specializes in creating dynamic and high-performing websites that drive business success. Our web development services combine the latest technologies with creative design to deliver responsive, user-friendly, and scalable websites tailored to your business needs.",
            list: ["Responsive Design", "SEO Optimized", "Fast Loading Speed", "End-to-End Development"],
        },
        webapplication: {
            text:
                "BigHost delivers reliable web application development services tailored to eCommerce, education, and retail industries. We specialize in creating scalable, secure, and user-friendly solutions using modern technologies, ensuring high performance and alignment with your business goals.",
            list: ["Customized Solution", "Innovative Technologies", "Secure Solutions"],
        },
        ecommerce: {
            text:
                "Boost your eCommerce business with cutting-edge solutions designed for scalability, security, and seamless user experiences. Our tailored eCommerce platforms combine modern features and intuitive design to enhance engagement and drive growth.",
            list: ["Custom Solutions", "Secure Payments", "Modern Features"],
        },
        pwa: {
            text:
                "Enhance your business's online presence with our next-gen Progressive Web Apps (PWAs). We deliver highly responsive, scalable, and dynamic PWAs that combine the power of mobile and web apps for lightning-fast performance and outstanding user experiences.",
            list: ["Lightning-Fast Performance", "Dynamic PWAs", "Outstanding UX"],
        },
        industries: {
            text:
                "BigHost offers innovative solutions across various industries, providing tailored web applications to optimize processes, enhance user experiences, and drive growth. From education and eCommerce to healthcare and logistics, we deliver scalable and secure digital solutions designed to meet the unique needs of each sector.",
            list: ["Education", "Real Estate", "Food & Hospitality", "Logistics & Transportation"],
        },
        solutions: {
            text:
                "BigHost provides innovative and efficient solutions to streamline business operations. Our advanced systems are designed to enhance productivity, improve customer engagement, and simplify complex workflows, offering scalable, secure, and user-friendly platforms to meet specific business needs.",
            list: ["CarDeal Quotation System", "Transport Bilty Software", "Property Management System"],
        },
    };

    const renderContent = () => {
        const content = tabContent[activeTab];
        return (
            <div>
                <p>{content.text}</p>
                <ul className="inline-list">
                    {content.list.map((item, index) => (
                        <li key={index}>
                            <FaCheckCircle className="check-icon" /> {item}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const getActiveImage = () => {
        const activeTabData = tabs.find((tab) => tab.key === activeTab);
        return activeTabData ? activeTabData.image : null;
    };

    return (
        <Container fluid className="tabcontainer">
            {/* Main Heading and Paragraph */}
            <Row>
                <Col>
                    <h1 className="text-center mt-3 mb-2 tab-heading">Our Professional Development Services</h1>
                    <p  className="text-center mb-4" style={{padding:'0 15%'}}>
                        Explore a wide range of development services tailored to your needs. From responsive websites to scalable applications, we help businesses innovate and grow with cutting-edge solutions.
                    </p>
                </Col>
            </Row>

            {/* Tab Header */}
            <Row>
                <Col>
                <div className="tab-header">
                        {/* Dropdown for Mobile View */}
                        <div className="dev-dropdown d-block d-md-none">
                            <Dropdown onSelect={(key) => setActiveTab(key)}>
                                <Dropdown.Toggle className="dev-dropdown-toggle">
                                    {tabs.find((tab) => tab.key === activeTab)?.label}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {tabs.map((tab) => (
                                        <Dropdown.Item key={tab.key} eventKey={tab.key}>
                                            {tab.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        {/* Tab Buttons for Larger Screens */}
                        {tabs.map((tab) => (
                            <button
                                key={tab.key}
                                className={`tab-button ${activeTab === tab.key ? "active" : ""} d-none d-md-inline-block`}
                                onClick={() => setActiveTab(tab.key)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </Col>
            </Row>

            {/* Tab Content */}
            <Row className="tab-content">
                <Col md={8} className="px-5">
                    <h2>{tabs.find((tab) => tab.key === activeTab)?.label}</h2>
                    {renderContent()}
                </Col>
                <Col md={4} className="d-none d-md-block">
                    <img
                        src={getActiveImage()}
                        alt={`${activeTab} illustration`}
                        className="img-fluid"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TabView;
