// import React, { useState } from "react";
// import '../MainCSS/domain.css';
// import { BiPhoneCall } from "react-icons/bi";
// import { FaCheckCircle } from "react-icons/fa";

// const Industries = () => {
//     const [selectedSection, setSelectedSection] = useState("Education and eLearning");

//     return (
//         <div className="container mt-2 maincotainer">
//             <div className="row">
//                 {/* Left Sidebar (col-md-4) */}
//                 <div className="col-md-4 bg-light">
//                     <p className="mainheading mb-2">Industries We Serve</p>
//                     <p className="headingborder"></p>
//                     <p className="text-muted mb-none">Delivering Industry-Specific Solutions for Unmatched Growth and Success</p>
//                     <ul className="list-group mb-5" style={{ maxHeight: '280px', overflowY: 'auto' }}>
//                         <li
//                             className={`list-group-item ${selectedSection === "Education and eLearning" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Education and eLearning")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Education and eLearning
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Food and Hospitality" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Food and Hospitality")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Food and Hospitality
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Retail and eCommerce" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Retail and eCommerce")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Retail and eCommerce
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Logistics and Transportation" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Logistics and Transportation")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Logistics and Transportation
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Manufacturing and Enterprise Automation" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Manufacturing and Enterprise Automation")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Manufacturing and Enterprise Automation
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Real Estate and Construction" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Real Estate and Construction")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Real Estate and Construction
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Media and Entertainment" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Media and Entertainment")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Media and Entertainment
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Nonprofits and NGOs" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Nonprofits and NGOs")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Nonprofits and NGOs
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Legal and Compliance" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Legal and Compliance")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Legal and Compliance
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Human Resources and Staffing" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Human Resources and Staffing")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Human Resources and Staffing
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Education for Skill Development" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Education for Skill Development")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Education for Skill Development
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Events and Conferences" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Events and Conferences")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Events and Conferences
//                         </li>
//                         <li
//                             className={`list-group-item ${selectedSection === "Tourism and Travel" ? "active" : ""}`}
//                             onMouseEnter={() => setSelectedSection("Tourism and Travel")}
//                             style={{ cursor: "pointer" }}
//                         >
//                             Tourism and Travel
//                         </li>
//                     </ul>
//                     {/* Footer for col-md-4 */}
//                     <div className="footer-left">
//                         <button className="btn btn-search">
//                             <BiPhoneCall
//                                 style={{ height: "20px", width: "20px", marginRight: "10px" }}
//                             />
//                             Schedule a Call
//                         </button>
//                     </div>
//                 </div>

//                 {/* Right Content (col-md-8) */}
//                 <div className="col-md-8 bg-white">
//                     {selectedSection === "Education and eLearning" && (
//                         <div className="w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Education and eLearning</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 We provide learning management systems (LMS), online certification platforms, virtual classrooms, and school management systems designed to enhance online education experiences and streamline administrative tasks.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Learning Management Systems (LMS) </p>
//                                     <p><FaCheckCircle className="checkcircle" />Online certification platforms </p>
//                                     <p><FaCheckCircle className="checkcircle" />Virtual classrooms for remote learning</p>
//                                     <p><FaCheckCircle className="checkcircle" />School and university management systems </p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Interactive course creation tools </p>
//                                     <p><FaCheckCircle className="checkcircle" />E-learning content development</p>
//                                     <p><FaCheckCircle className="checkcircle" />Mobile-friendly educational solutions</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Food and Hospitality" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Food and Hospitality</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 Our solutions include restaurant apps, food delivery integration, hospitality management systems, and reservation platforms, enabling businesses to improve customer service, streamline operations, and increase sales.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Online ordering and delivery systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Restaurant management tools </p>
//                                     <p><FaCheckCircle className="checkcircle" />Reservation and booking management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Menu management and customization </p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Payment gateway integration </p>
//                                     <p><FaCheckCircle className="checkcircle" />Real-time inventory tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Customer feedback and review systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Staff scheduling and payroll systems</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Retail and eCommerce" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Retail and eCommerce</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             <p className="text">
//                                 We specialize in developing online stores, inventory management solutions, POS (Point of Sale) systems, and omnichannel retail solutions, helping businesses deliver seamless shopping experiences both online and in-store.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Custom eCommerce store development</p>
//                                     <p><FaCheckCircle className="checkcircle" />Inventory and order management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Payment gateway integration</p>
//                                     <p><FaCheckCircle className="checkcircle" />Product recommendation engines</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Sales analytics and reporting</p>
//                                     <p><FaCheckCircle className="checkcircle" />Customer profile systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Mobile and responsive designs</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Logistics and Transportation" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Logistics and Transportation</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We offer shipment tracking systems, fleet management tools, route optimization software, and warehouse management solutions, helping businesses improve logistics efficiency, reduce costs, and enhance service delivery.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Real-time shipment tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" /> Vehicle tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Warehouse management systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Inventory and stock management</p>
//                                     <p><FaCheckCircle className="checkcircle" />GPS and telematics integration</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Shipping and delivery scheduling</p>
//                                     <p><FaCheckCircle className="checkcircle" />Automated notifications and alerts</p>
//                                     <p><FaCheckCircle className="checkcircle" />Analytics and performance dashboards</p>
//                                     <p><FaCheckCircle className="checkcircle" />Integration with ERP and CRM systems</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Manufacturing and Enterprise Automation" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Manufacturing and Enterprise Automation</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 Our solutions include ERP systems, production tracking tools, inventory management, and smart factory integrations, designed to optimize operations and improve real-time decision-making in manufacturing processes.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Enterprise Resource Planning (ERP) systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Production tracking and monitoring</p>
//                                     <p><FaCheckCircle className="checkcircle" />Real-time data analytics</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Employee performance tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Automated inventory management</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Real Estate and Construction" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Real Estate and Construction</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We provide property listing portals, virtual tours, project management tools, and lead generation platforms, helping real estate professionals streamline their operations and enhance customer engagement.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Property listing and management portals</p>
//                                     <p><FaCheckCircle className="checkcircle" />Lead generation and CRM integration</p>
//                                     <p><FaCheckCircle className="checkcircle" />Property search and filter functionality</p>
//                                     <p><FaCheckCircle className="checkcircle" />Real-time pricing and availability updates</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Client and contract management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Online payment and rental systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Interactive maps and location-based services</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Media and Entertainment" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Media and Entertainment</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We develop OTT platforms, content distribution systems, event management tools, and fan interaction portals that enable businesses in the media and entertainment sector to reach audiences and manage content effectively.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Over-the-top (OTT) media platforms</p>
//                                     <p><FaCheckCircle className="checkcircle" />Content distribution & management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Event management systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Subscription & billing systems</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Social media integration</p>
//                                     <p><FaCheckCircle className="checkcircle" />Live streaming platforms</p>
//                                     <p><FaCheckCircle className="checkcircle" />Fan interaction & engagement portals</p>
//                                     <p><FaCheckCircle className="checkcircle" />Analytics for user engagement</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Nonprofits and NGOs" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Nonprofits and NGOs</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We offer solutions for donation tracking, volunteer management, event organization, and impact reporting systems, helping nonprofits manage resources, track contributions, and measure their impact.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Donation management and tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Volunteer coordination and management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Event management and ticketing systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Impact reporting and analytics</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Integration with payment gateways</p>
//                                     <p><FaCheckCircle className="checkcircle" />CRM for donor and volunteer relations</p>
//                                     <p><FaCheckCircle className="checkcircle" />Mobile app for donations and updates</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Legal and Compliance" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Legal and Compliance</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We provide case management systems, legal documentation portals, and compliance tracking tools, enabling legal professionals and organizations to streamline case handling and ensure regulatory adherence.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Case management and tracking systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Legal document storage and sharing</p>
//                                     <p><FaCheckCircle className="checkcircle" />Compliance tracking and management</p>
//                                     <p><FaCheckCircle className="checkcircle" />E-signature integration</p>
//                                     <p><FaCheckCircle className="checkcircle" />Client management systems</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Court and legal proceeding tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Billing and invoicing systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Collaboration and communication portals</p>
//                                     <p><FaCheckCircle className="checkcircle" />Automated reminders and deadlines</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Human Resources and Staffing" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Human Resources and Staffing</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 Our solutions include recruitment platforms, employee portals, payroll systems, and performance tracking tools, helping HR departments improve talent management and optimize workforce performance.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Recruitment & applicant tracking systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Employee portals</p>
//                                     <p><FaCheckCircle className="checkcircle" />Payroll & benefits management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Performance evaluation & tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Employee scheduling & attendance management</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />HR analytics & reporting tools</p>
//                                     <p><FaCheckCircle className="checkcircle" />Learning & development portals</p>
//                                     <p><FaCheckCircle className="checkcircle" />Employee engagement & feedback systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Compliance tracking for HR regulations</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Education for Skill Development" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Education for Skill Development</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We build platforms for vocational training, corporate eLearning, and certification programs, providing individuals and organizations with the tools to enhance skills and offer continuous learning opportunities.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Vocational training platforms</p>
//                                     <p><FaCheckCircle className="checkcircle" />Corporate eLearning and training solutions</p>
//                                     <p><FaCheckCircle className="checkcircle" />Certification and accreditation systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Interactive course modules and content</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Real-time progress tracking</p>
//                                     <p><FaCheckCircle className="checkcircle" />Quizzes and assessments</p>
//                                     <p><FaCheckCircle className="checkcircle" />Integration with job boards and employers</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Events and Conferences" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Events and Conferences</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 Our services include ticketing systems, event scheduling tools, attendee management, and live streaming apps, designed to enhance the event experience for organizers and participants alike.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Online ticketing and booking systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Live streaming and virtual events</p>
//                                     <p><FaCheckCircle className="checkcircle" />Attendee registration and management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Events and agenda management</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Sponsorship and exhibitor management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Event analytics and reporting</p>
//                                     <p><FaCheckCircle className="checkcircle" />Integration with social media and email marketing</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {selectedSection === "Tourism and Travel" && (
//                         <div className="pb-5 w-100">
//                             <div className="d-flex justify-content-between align-items-center">
//                                 <p className="mainheading mb-2">Tourism and Travel</p>
//                                 <button className="btn btn-view">View All</button>
//                             </div>
//                             <p className="headingborder"></p>
//                             {/* <h5 style={{fontWeight:"bold"}}>Professional Business Email Hosting</h5> */}
//                             <p className="text">
//                                 We offer booking systems, tour management solutions, itinerary planning, and AI-driven travel apps, helping businesses in the travel industry provide personalized and efficient services to their customers.</p>
//                             <div className="d-flex">
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Travel booking and reservation systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />AI-driven travel recommendations</p>
//                                     <p><FaCheckCircle className="checkcircle" />Multi-device compatibility</p>
//                                     <p><FaCheckCircle className="checkcircle" />Mobile travel apps with offline access</p>
//                                     <p><FaCheckCircle className="checkcircle" />Payment gateway integrations</p>
//                                 </div>
//                                 <div className="col-6">
//                                     <p><FaCheckCircle className="checkcircle" />Real-time updates and alerts</p>
//                                     <p><FaCheckCircle className="checkcircle" />Travel documentation and visa management</p>
//                                     <p><FaCheckCircle className="checkcircle" />Customer review and rating systems</p>
//                                     <p><FaCheckCircle className="checkcircle" />Integration with third-party travel services</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Footer for col-md-8 */}
//                     <div className="footer-right d-flex justify-content-between">
//                         <p className="text-muted">
//                             <strong>sales@bighostindia.in</strong>
//                         </p>
//                         <p className="text-muted">
//                             <strong>+91 7972719376</strong>
//                         </p>
//                         {/* <p className="text-muted">
//                             HR: <strong>+91 9023728510</strong>
//                         </p> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Industries;



import React, { useState } from "react";
import { Container, Row, Col, Button, ListGroup, Form } from "react-bootstrap";
import '../MainCSS/domain.css';
import { BiPhoneCall } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";

const Industries = () => {
    const [selectedSection, setSelectedSection] = useState("Education and eLearning");

    return (
        <Container className="mt-2 maincotainer">
            <Row>
                {/* Left Sidebar (col-md-4) */}
                <Col md={4} className="bg-light">
                    <p className="mainheading mb-2">Industries We Serve</p>
                    <p className="headingborder"></p>
                    <p className="text-muted mb-none">Delivering Industry-Specific Solutions for Unmatched Growth and Success</p>
                    <ListGroup className="mb-5" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Education and eLearning"}
                            onMouseEnter={() => setSelectedSection("Education and eLearning")}
                        >
                            Education and eLearning
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Food and Hospitality"}
                            onMouseEnter={() => setSelectedSection("Food and Hospitality")}
                        >
                            Food and Hospitality
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Retail and eCommerce"}
                            onMouseEnter={() => setSelectedSection("Retail and eCommerce")}
                        >
                            Retail and eCommerce
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Logistics and Transportation"}
                            onMouseEnter={() => setSelectedSection("Logistics and Transportation")}
                        >
                            Logistics and Transportation
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Manufacturing and Enterprise Automation"}
                            onMouseEnter={() => setSelectedSection("Manufacturing and Enterprise Automation")}
                        >
                            Manufacturing and Enterprise Automation
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            active={selectedSection === "Real Estate and Construction"}
                            onMouseEnter={() => setSelectedSection("Real Estate and Construction")}
                        >
                            Real Estate and Construction
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Media and Entertainment"}
                            onMouseEnter={() => setSelectedSection("Media and Entertainment")}
                        >
                            Media and Entertainment
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Nonprofits and NGOs"}
                            onMouseEnter={() => setSelectedSection("Nonprofits and NGOs")}
                        >
                            Nonprofits and NGOs
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Legal and Compliance"}
                            onMouseEnter={() => setSelectedSection("Legal and Compliance")}
                        >
                            Legal and Compliance
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Human Resources and Staffing"}
                            onMouseEnter={() => setSelectedSection("Human Resources and Staffing")}
                        >
                            Human Resources and Staffing
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Education for Skill Development"}
                            onMouseEnter={() => setSelectedSection("Education for Skill Development")}
                        >
                            Education for Skill Development
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Events and Conferences"}
                            onMouseEnter={() => setSelectedSection("Events and Conferences")}
                        >
                            Events and Conferences
                        </ListGroup.Item>

                        <ListGroup.Item
                            action
                            active={selectedSection === "Tourism and Travel"}
                            onMouseEnter={() => setSelectedSection("Tourism and Travel")}
                        >
                            Tourism and Travel
                        </ListGroup.Item>

                    </ListGroup>
                    <Row className="footer-left">
                        <Col>
                            <Button className="btn btn-search">
                                <BiPhoneCall style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                                Schedule a Call
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* Right Content (col-md-8) */}
                <Col md={8} className="bg-white">
                    {selectedSection === "Education and eLearning" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Education and eLearning</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We provide learning management systems (LMS), online certification platforms, virtual classrooms, and school management systems designed to enhance online education experiences and streamline administrative tasks.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Learning Management Systems (LMS) </p>
                                    <p><FaCheckCircle className="checkcircle" />Online certification platforms </p>
                                    <p><FaCheckCircle className="checkcircle" />Virtual classrooms for remote learning</p>
                                    <p><FaCheckCircle className="checkcircle" />School and university management systems </p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Interactive course creation tools </p>
                                    <p><FaCheckCircle className="checkcircle" />E-learning content development</p>
                                    <p><FaCheckCircle className="checkcircle" />Mobile-friendly educational solutions</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Food and Hospitality" && (
                        <Container className="pb-5 w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Food and Hospitality</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Our solutions include restaurant apps, food delivery integration, hospitality management systems, and reservation platforms, enabling businesses to improve customer service, streamline operations, and increase sales.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Online ordering and delivery systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Restaurant management tools </p>
                                    <p><FaCheckCircle className="checkcircle" />Reservation and booking management</p>
                                    <p><FaCheckCircle className="checkcircle" />Menu management and customization </p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Payment gateway integration </p>
                                    <p><FaCheckCircle className="checkcircle" />Real-time inventory tracking</p>
                                    <p><FaCheckCircle className="checkcircle" />Customer feedback and review systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Staff scheduling and payroll systems</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Retail and eCommerce" && (
                        <Container className="pb-5 w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Retail and eCommerce</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We specialize in developing online stores, inventory management solutions, POS (Point of Sale) systems, and omnichannel retail solutions, helping businesses deliver seamless shopping experiences both online and in-store.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Custom eCommerce store development</p>
                                    <p><FaCheckCircle className="checkcircle" />Inventory and order management</p>
                                    <p><FaCheckCircle className="checkcircle" />Payment gateway integration</p>
                                    <p><FaCheckCircle className="checkcircle" />Product recommendation engines</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Sales analytics and reporting</p>
                                    <p><FaCheckCircle className="checkcircle" />Customer profile systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Mobile and responsive designs</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Logistics and Transportation" && (
                        <Container className="pb-5 w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Logistics and Transportation</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We offer shipment tracking systems, fleet management tools, route optimization software, and warehouse management solutions, helping businesses improve logistics efficiency, reduce costs, and enhance service delivery.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Real-time shipment tracking</p>
                                    <p><FaCheckCircle className="checkcircle" /> Vehicle tracking</p>
                                    <p><FaCheckCircle className="checkcircle" />Warehouse management systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Inventory and stock management</p>
                                    <p><FaCheckCircle className="checkcircle" />GPS and telematics integration</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Shipping and delivery scheduling</p>
                                    <p><FaCheckCircle className="checkcircle" />Automated notifications and alerts</p>
                                    <p><FaCheckCircle className="checkcircle" />Analytics and performance dashboards</p>
                                    <p><FaCheckCircle className="checkcircle" />Integration with ERP and CRM systems</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Manufacturing and Enterprise Automation" && (
                        <Container className="pb-5 w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Manufacturing and Enterprise Automation</p>
                                </Col>
                                <Col>
                                    <Button className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We provide manufacturing automation solutions, ERP systems, predictive maintenance tools, and IoT-based monitoring systems, enhancing operational efficiency and enabling businesses to scale.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Industrial IoT solutions</p>
                                    <p><FaCheckCircle className="checkcircle" />Predictive maintenance systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Supply chain management software</p>
                                    <p><FaCheckCircle className="checkcircle" />Automation of manufacturing processes</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" /> ERP software development</p>
                                    <p><FaCheckCircle className="checkcircle" />Smart factory solutions</p>
                                    <p><FaCheckCircle className="checkcircle" />Data analytics and optimization tools</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Real Estate and Construction" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Real Estate and Construction</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We provide property listing portals, virtual tours, project management tools, and lead generation platforms, helping real estate professionals streamline their operations and enhance customer engagement.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Property listing and management portals</p>
                                    <p><FaCheckCircle className="checkcircle" />Lead generation and CRM integration</p>
                                    <p><FaCheckCircle className="checkcircle" />Property search and filter functionality</p>
                                    <p><FaCheckCircle className="checkcircle" />Real-time pricing and availability updates</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Client and contract management</p>
                                    <p><FaCheckCircle className="checkcircle" />Online payment and rental systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Interactive maps and location-based services</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Media and Entertainment" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Media and Entertainment</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We develop OTT platforms, content distribution systems, event management tools, and fan interaction portals that enable businesses in the media and entertainment sector to reach audiences and manage content effectively.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Over-the-top (OTT) media platforms</p>
                                    <p><FaCheckCircle className="checkcircle" />Content distribution & management</p>
                                    <p><FaCheckCircle className="checkcircle" />Event management systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Subscription & billing systems</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Social media integration</p>
                                    <p><FaCheckCircle className="checkcircle" />Live streaming platforms</p>
                                    <p><FaCheckCircle className="checkcircle" />Fan interaction & engagement portals</p>
                                    <p><FaCheckCircle className="checkcircle" />Analytics for user engagement</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Nonprofits and NGOs" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Nonprofits and NGOs</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We offer solutions for donation tracking, volunteer management, event organization, and impact reporting systems, helping nonprofits manage resources, track contributions, and measure their impact.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Donation management and tracking</p>
                                    <p><FaCheckCircle className="checkcircle" />Volunteer coordination and management</p>
                                    <p><FaCheckCircle className="checkcircle" />Event management and ticketing systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Impact reporting and analytics</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Integration with payment gateways</p>
                                    <p><FaCheckCircle className="checkcircle" />CRM for donor and volunteer relations</p>
                                    <p><FaCheckCircle className="checkcircle" />Mobile app for donations and updates</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Legal and Compliance" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Legal and Compliance</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We provide case management systems, legal documentation portals, and compliance tracking tools, enabling legal professionals and organizations to streamline case handling and ensure regulatory adherence.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Case management and tracking systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Legal document storage and sharing</p>
                                    <p><FaCheckCircle className="checkcircle" />Compliance tracking and management</p>
                                    <p><FaCheckCircle className="checkcircle" />E-signature integration</p>
                                    <p><FaCheckCircle className="checkcircle" />Client management systems</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Court and legal proceeding tracking</p>
                                    <p><FaCheckCircle className="checkcircle" />Billing and invoicing systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Collaboration and communication portals</p>
                                    <p><FaCheckCircle className="checkcircle" />Automated reminders and deadlines</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Human Resources and Staffing" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Human Resources and Staffing</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Our solutions include recruitment platforms, employee portals, payroll systems, and performance tracking tools, helping HR departments improve talent management and optimize workforce performance.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Recruitment & applicant tracking systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Employee portals</p>
                                    <p><FaCheckCircle className="checkcircle" />Payroll & benefits management</p>
                                    <p><FaCheckCircle className="checkcircle" />Performance evaluation & tracking</p>
                                    <p><FaCheckCircle className="checkcircle" />Employee scheduling & attendance management</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />HR analytics & reporting tools</p>
                                    <p><FaCheckCircle className="checkcircle" />Learning & development portals</p>
                                    <p><FaCheckCircle className="checkcircle" />Employee engagement & feedback systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Compliance tracking for HR regulations</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Education for Skill Development" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Education for Skill Development</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We build platforms for vocational training, corporate eLearning, and certification programs, providing individuals and organizations with the tools to enhance skills and offer continuous learning opportunities.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Vocational training platforms</p>
                                    <p><FaCheckCircle className="checkcircle" />Corporate eLearning and training solutions</p>
                                    <p><FaCheckCircle className="checkcircle" />Certification and accreditation systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Interactive course modules and content</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Skills assessment and testing systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Learning management systems (LMS)</p>
                                    <p><FaCheckCircle className="checkcircle" />Progress tracking & gamification</p>
                                    <p><FaCheckCircle className="checkcircle" />Job placement & internship opportunities</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Events and Conferences" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Events and Conferences</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                Our services include ticketing systems, event scheduling tools, attendee management, and live streaming apps, designed to enhance the event experience for organizers and participants alike.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Online ticketing and booking systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Live streaming and virtual events</p>
                                    <p><FaCheckCircle className="checkcircle" />Attendee registration and management</p>
                                    <p><FaCheckCircle className="checkcircle" />Events and agenda management</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Sponsorship and exhibitor management</p>
                                    <p><FaCheckCircle className="checkcircle" />Event analytics and reporting</p>
                                    <p><FaCheckCircle className="checkcircle" />Integration with social media and email marketing</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {selectedSection === "Tourism and Travel" && (
                        <Container className="w-100">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <p className="mainheading mb-2">Tourism and Travel</p>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="btn-view">View All</Button>
                                </Col>
                            </Row>
                            <p className="headingborder"></p>
                            <p className="text">
                                We offer booking systems, tour management solutions, itinerary planning, and AI-driven travel apps, helping businesses in the travel industry provide personalized and efficient services to their customers.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Travel booking and reservation systems</p>
                                    <p><FaCheckCircle className="checkcircle" />AI-driven travel recommendations</p>
                                    <p><FaCheckCircle className="checkcircle" />Multi-device compatibility</p>
                                    <p><FaCheckCircle className="checkcircle" />Mobile travel apps with offline access</p>
                                    <p><FaCheckCircle className="checkcircle" />Payment gateway integrations</p>
                                </Col>
                                <Col md={6}>
                                    <p><FaCheckCircle className="checkcircle" />Real-time updates and alerts</p>
                                    <p><FaCheckCircle className="checkcircle" />Travel documentation and visa management</p>
                                    <p><FaCheckCircle className="checkcircle" />Customer review and rating systems</p>
                                    <p><FaCheckCircle className="checkcircle" />Integration with third-party travel services</p>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    <Row className="footer-right d-flex justify-content-between">
                        <Col>
                            <p className="text-muted">
                                <strong>sales@bighostindia.in</strong>
                            </p>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">
                                <strong>+91 7972719376</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Industries;
