
// import React, { useState } from "react";
// import { Container, Navbar, Nav, NavDropdown, Button, Row, Col } from "react-bootstrap";

// import Domain from "../MegaMenu/Domain";
// import CloudHosting from "../MegaMenu/CloudHosting";
// import ApplicationDevelopment from "../MegaMenu/ApplicationDevelopment";
// import Industries from "../MegaMenu/Industries";
// import Solutions from "../MegaMenu/Solutions";
// import AboutMenu from "../MegaMenu/About";

// import "../MainCSS/header.css"; // Add your custom styles
// import logo1 from "../BigHost-India-Logo.png";

// const Header = () => {
//   const [megaMenuContent, setMegaMenuContent] = useState(null);

//   const handleMouseEnter = (content) => {
//     setMegaMenuContent(content);
//   };

//   const handleMouseLeave = () => {
//     setMegaMenuContent(null);
//   };

//   return (
//     <header className="header">
//       <Navbar
//         expand="lg"
//         className="p-2"
//         bg="white"
//         fixed="top"
//         style={{
//           zIndex: 1050,
//           boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//           overflow:'hidden'
//         }}
//       >
//         <Container fluid className="nav-container">
//           {/* Logo Section */}
//           <Navbar.Brand href="/">
//             <img src={logo1} alt="Company Logo" height="36" />
//           </Navbar.Brand>

//           {/* Mobile Toggle */}
//           <Navbar.Toggle aria-controls="basic-navbar-nav"  />

//           {/* Navigation */}
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto me-auto align-items-start nv-items">
//               <NavDropdown
//                 title="Domain"
//                 id="domain-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Domain />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//               <NavDropdown
//                 title="Cloud Hosting"
//                 id="cloud-hosting-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<CloudHosting />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//               <NavDropdown
//                 title="Application Development"
//                 id="application-development-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<ApplicationDevelopment />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//               <NavDropdown
//                 title="Industries"
//                 id="industries-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Industries />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//               <NavDropdown
//                 title="Solutions"
//                 id="solutions-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Solutions />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//               <NavDropdown
//                 title="About"
//                 id="about-menu-dropdown"
//                 className="mega-menu-dropdown mx-2"
//                 onMouseEnter={() => handleMouseEnter(<AboutMenu />)}
//                 onMouseLeave={handleMouseLeave}
//               />
//             </Nav>

//             {/* Buttons */}
//             <div className="d-flex ms-2 mt-2 mt-lg-0">
//               <Button className="supportbtn me-2" href="/support">
//                 Support
//               </Button>
//               <Button className="loginbtn" href="/login">
//                 Login
//               </Button>
//             </div>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Mega Menu Content */}
//       {megaMenuContent && (
//         <Container
//           fluid
//           className="mega-menu-container"
//           onMouseEnter={() => handleMouseEnter(megaMenuContent)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <Row>
//             <Col>{megaMenuContent}</Col>
//           </Row>
//         </Container>
//       )}
//     </header>
//   );
// };

// export default Header;





// import React, { useState } from "react";
// import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";

// import Domain from "../MegaMenu/Domain";
// import CloudHosting from "../MegaMenu/CloudHosting";
// import ApplicationDevelopment from "../MegaMenu/ApplicationDevelopment";
// import Industries from "../MegaMenu/Industries";
// import Solutions from "../MegaMenu/Solutions";
// import AboutMenu from "../MegaMenu/About";
// import Support from "../MegaMenu/Support";

// import "../MainCSS/header.css"; // Add your custom styles
// import logo1 from "../BigHost-India-Logo.png";

// const Header = () => {
//   // State for displaying the content of mega menus in the same window
//   const [megaMenuContent, setMegaMenuContent] = useState(null);

//   // Handler for mouse enter to update the mega menu content
//   const handleMouseEnter = (content) => {
//     setMegaMenuContent(content);
//   };

//   // Handler for mouse leave to reset the mega menu content
//   const handleMouseLeave = () => {
//     setMegaMenuContent(null);
//   };

//   return (
//     <header className="header">
//           <Navbar                                                             
//             expand="lg"
//           >
//            <Container fluid className="nav-container">
//           {/* Logo Section */}
//           <Navbar.Brand href="/">
//             <img src={logo1} alt="Company Logo" height="36" />
//           </Navbar.Brand>

//           {/* Toggle for Mobile View */}
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />

//           {/* Navigation Items */}
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto">
//               {/* Domain Dropdown */}
//               <NavDropdown
//                 title="Domain"
//                 id="domain-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Domain />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//               {/* Cloud Hosting Dropdown */}
//               <NavDropdown
//                 title="Cloud Hosting"
//                 id="cloud-hosting-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<CloudHosting />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//               {/* Application Development Dropdown */}
//               <NavDropdown
//                 title="Application Development"
//                 id="application-development-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<ApplicationDevelopment />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//               {/* Industries Dropdown */}
//               <NavDropdown
//                 title="Industries"
//                 id="industries-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Industries />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//               {/* Solutions Dropdown */}
//               <NavDropdown
//                 title="Solutions"
//                 id="solutions-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Solutions />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//               {/* About Menu Dropdown */}
//               <NavDropdown
//                 title="About"
//                 id="about-menu-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<AboutMenu />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>

//                 {/* Support Dropdown */}
//                 <NavDropdown
//                 title="Support"
//                 id="support-dropdown"
//                 className="mega-menu-dropdown  mx-2"
//                 onMouseEnter={() => handleMouseEnter(<Support />)}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 {/* No specific content here */}
//               </NavDropdown>
//             </Nav>

//             {/* Support and Login Buttons */}
//             <div className="ms-2 d-flex">
//               {/* <Button className="supportbtn  mx-2" href="/support">
//                 Support
//               </Button> */}
//               <Button className="loginbtn  " href="https://billing.bighostindia.in/login">
//                 Client Login
//               </Button>
//             </div>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Container for displaying the mega menu content */}
//       {megaMenuContent && (
//         <div className="mega-menu-container" onMouseEnter={() => handleMouseEnter(megaMenuContent)} onMouseLeave={handleMouseLeave}>
//           {megaMenuContent}
//         </div>
//       )}
//     </header>
//   );
// };

// export default Header;



import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import Domain from "../MegaMenu/Domain";
import CloudHosting from "../MegaMenu/CloudHosting";
import ApplicationDevelopment from "../MegaMenu/ApplicationDevelopment";
import Industries from "../MegaMenu/Industries";
import Solutions from "../MegaMenu/Solutions";
import AboutMenu from "../MegaMenu/About";
import Support from "../MegaMenu/Support";

import "../MainCSS/header.css"; // Add your custom styles
import logo1 from "../BigHost-India-Logo.png";

const Header = () => {
  // State for displaying the content of mega menus in the same window
  const [megaMenuContent, setMegaMenuContent] = useState(null);

  const [sidebarShow, setSidebarShow] = useState(false);

  // Handler for toggle sidebar visibility
  const toggleSidebar = () => setSidebarShow(!sidebarShow);

  // Handler for mouse enter to update the mega menu content
  const handleMouseEnter = (content) => {
    setMegaMenuContent(content);
  };

  // Handler for mouse leave to reset the mega menu content
  const handleMouseLeave = () => {
    setMegaMenuContent(null);
  };

  return (
    <header className="header">
      <Navbar expand="lg">
        <Container fluid className="nav-container">
          <Navbar.Brand href="/">
            <img src={logo1} alt="Company Logo" height="36" />
          </Navbar.Brand>

          {/* Toggle for Mobile View */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleSidebar} />

          {/* Navigation Items */}
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
            <Nav className="ms-auto">
              <NavDropdown
                title="Domain"
                id="domain-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<Domain />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="Cloud Hosting"
                id="cloud-hosting-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<CloudHosting />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="Application Development"
                id="application-development-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<ApplicationDevelopment />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="Industries"
                id="industries-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<Industries />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="Solutions"
                id="solutions-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<Solutions />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="About"
                id="about-menu-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<AboutMenu />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>

              <NavDropdown
                title="Support"
                id="support-dropdown"
                className="mega-menu-dropdown mx-2"
                onMouseEnter={() => handleMouseEnter(<Support />)}
                onMouseLeave={handleMouseLeave}
              ></NavDropdown>
            </Nav>

            <div className="ms-2 d-flex">
              <Button className="loginbtn" href="https://billing.bighostindia.in/login">
                Client Login
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Mega Menu Content */}
      {megaMenuContent && (
        <div
          className="mega-menu-container"
          onMouseEnter={() => handleMouseEnter(megaMenuContent)}
          onMouseLeave={handleMouseLeave}
        >
          {megaMenuContent}
        </div>
      )}

      {/* Sidebar for Mobile */}
      <Offcanvas show={sidebarShow} onHide={toggleSidebar} placement="start" className="offcanvas-sidebar">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><img src={logo1} alt="Company Logo" height="36" /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Link to="#" className="mobile-head">Domain</Link>
            <Link to="#" className="mobile-head">Cloud Hosting</Link>
            <Link to="#" className="mobile-head">Application Development</Link>
            <Link to="#" className="mobile-head">Industries</Link>
            <Link to="#" className="mobile-head">Solutions</Link>
            <Link to="#" className="mobile-head">About</Link>
            <Link to="#" className="mobile-head">Support</Link>
            <Link to="https://billing.bighostindia.in/login" className="mobile-head">Client Login</Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
